import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";

import Auth from "../../auth/Auth";

const InsuranceAgenciesLogout = () => {
  const navigate = useNavigate();
  const token = Auth.InsuranceToken();

  // Localstorage get Email section start

  const getEmail = localStorage.getItem("insurance_forget_password_email");

  // Localstorage get Email section start

  const [loading, setLoading] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [newPasswordIcon, setNewPasswordIcon] = useState(true);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(true);

  const passwordPattren = new RegExp(
    "(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).*$"
  );
  const newPasswordCheck = passwordPattren.test(newPassword);

  // Logout API section start
  const handleLogOut = async () => {
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/agency-logout`,
      requestOption
    );
    if (response.status >= 200 || response.status <= 399) {
      const data = await response.json();
      setLoading(true);
      localStorage.clear();
      // localStorage.setItem("network",1);
      // localStorage.setItem("storepage",1);
      toast.success(data.Success, { autoClose: 2000 });
      navigate("/insurance_agency_login");
      localStorage.removeItem("insuranceToken");
      localStorage.removeItem("insurance_profile");
    }
    if (response.status >= 400 || response.status <= 500) {
      const error = await response.json();
      toast.error(error.message);
      setLoading(false);
    }
  };

  // Logout API section end

  const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [password2, setPassword2] = useState(false);

  // Reset Password API section start
  const handleResetPassword = async () => {
    // setLoading(true);
    const resetPasswordKey = {
      email: getEmail,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(resetPasswordKey),
    };

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/password-update`,
      requestOption
    );

    if (response.status >= 200 && response.status <= 399) {
      const data = await response.json();
      console.log(data);
      // toast.success(data.message)
      setSuccessMessage(true);
      setPassword2(false);
      // setLoading(false);
      setNewPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }

    if (response.status >= 400 && response.status <= 500) {
      const errorReset = await response.json();
      console.log(errorReset.message.password[0]);
      // if (errorReset.message.password[0] === "Password must contain 1 uppercase, 1 lowercase, 1 special character and 1 numeric.") {
      //     setStrongPassword(true)
      // }
      // setLoading(false);
      setPassword2(true);
    }
  };

  // when click cross symbol & cancel button then empty enput field section start

  console.log(JSON.parse(localStorage.getItem("agency_login"))?.logo !== '', ">>>");
  return (
    <>
      <ToastContainer theme="dark" />
      <div className="select-user ">
        <div className="profile-icon">
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              <span className="user-img">
                {/* <img src={localStorage.getItem('profile_img')} alt="user-img" onClick={() => setDropDown(!dropDown)} /> */}

                {JSON.parse(localStorage.getItem("agency_login"))?.logo !== '' ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/images/${JSON.parse(localStorage.getItem("agency_login"))?.logo}`}
                    alt="user-img"
                    onClick={() => setDropDown(!dropDown)}
                  />
                ) : (
                  <img
                    src="assets/images/avtar.webp"
                    alt="user-img"
                    onClick={() => setDropDown(!dropDown)}
                  />
                )}

                <span className="arrowicon">
                  <img
                    src="assets/images/drop_arrow.svg"
                    alt="dropdown_arror"
                  />
                </span>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <label onClick={() => setIsModalVisible(true)}>Logout</label>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* Logout confirm modal section start */}

      <Modal
        show={isModalVisible}
        centered
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Teacher Name List</Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
          <img src="assets/images/logout.svg" alt="logout" />
          <p>
            Are you sure
            <br /> you want to logout!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cust" onClick={() => setIsModalVisible(false)}>
            No
          </Button>
          <Button variant="cust" onClick={() => handleLogOut()}>
            Yes
          </Button>
        </Modal.Footer>
        {/* Loading section start */}
        {loading ? (
          <div className="main-loader logout-loader">
            <Spinner animation="border" className="loader" variant="light" />
          </div>
        ) : (
          ""
        )}
        {/* Loading section end */}
      </Modal>
    </>
  );
};
export default InsuranceAgenciesLogout;
