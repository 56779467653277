import React from "react";

import { NavLink } from "react-router-dom";

const superAdmin = [
    {
        id: 1,
        url: "/insurance",
        label: "Insurance Agencies",
        className1: "hover_active_img",
        className2: "active_img",
        img1: "assets/images/security_safe_blue.svg",
        img2: "assets/images/security_safe_white.svg",
        alt1: "security_safe_blue",
        alt2: "security_safe_white"
    },
    {
        id: 2,
        url: "/claims_list",
        label: "Claims",
        className1: "hover_active_img",
        className2: "active_img",
        img1: "assets/images/claims_blue.svg",
        img2: "assets/images/note.svg",
        alt1: "claims_blue",
        alt2: "claims_white"
    },
    {
        id: 3,
        url: "/users",
        label: "Users",
        className1: "hover_active_img",
        className2: "active_img",
        img1: "assets/images/user_blue.png",
        img2: "assets/images/user_white.png",
        alt1: "users_blue",
        alt2: "users_white"
    }
]

const Sidebar = () => {
    return (
        <div className="sidebar-nav ">
            <div className="sidebar">
                <div className="logo-area">
                    <img src="assets/images/logo_inner.png" alt="logo-inner" />
                </div>
                <ul>
                    {/* Super Admin section start */}
                    {superAdmin?.map((data) => {
                        const { id, url, label, className1, className2, img1, img2, alt1, alt2 } = data;
                        return (
                            <li key={id}>
                                <NavLink to={url}>
                                    <span>
                                        <img src={img1} alt={alt1} className={className1} />
                                        <img src={img2} alt={alt2} className={className2} />
                                    </span>
                                    <span>
                                        {label} </span>
                                </NavLink>
                            </li>
                        )
                    })}
                    {/* Super Admin section start */}
                </ul>
            </div>
        </div>
    )
};
export default Sidebar;