import React, { useEffect, useState, useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import Auth from "../../auth/Auth";

import InNetwork from "./InNetwork";
import Logout from "../onboarding/Logout";
import Sidebar from "../../sidebar/Sidebar";



const limit = 25;

function debounce(func, timeout = 500) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}


const Insurence = () => {
    
    const getNetwork = localStorage.getItem("network");
    const storepage = localStorage.getItem("storepage");

    const navigate = useNavigate();
    const convertNumber = Number(getNetwork);
    const convertNumberPage = Number(storepage);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(convertNumberPage);
    const [network, setNetwork] = useState(convertNumber);
    const [networkResponse, setNetworkResponse] = useState({});
    
    
    const handleChangeTab = (e) => {
        console.log("00",+e.target.value);
        // localStorage.setItem("network",+e.target.value)
        // setNetwork(+e.target.value);
        setPage(1);
    }

    const inNetwork = useCallback(async (page, network = 0, search) => {
        setLoading(true);
        const requestOption = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Auth.token()}`,
            },
        };
        const res = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/insurance-angencies-list?limit=${page > 25 ? page : 25}&page=${page ? page : 1}&network=${network}${search ? '&search=' + search : ''
            }`,
            requestOption
        );

        if (res.status >= 200 && res.status <= 399) {
            const data = await res.json();
            setNetworkResponse(data?.claimList);
            setLoading(false);
        };
        if (res.status === 401) {
            localStorage.removeItem("token");
            navigate("/");
        };

        if (res.status >= 400 && res.status <= 500) {
            const error = await res.json();
            toast.error(error?.message);
            setLoading(false);
        };
        // eslint-disable-next-line
    }, []);

    // Number split formating "+1 (234) 567-8900" section start
    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(2|)?(\d{3})(\d{3})(\d{4})(\d{0,5})$/);
        // console.log('match',match);
        if (match) {
            // var intlCode = (match[1] ? '+1 ' : '');
            // return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
            return ['(', match[1], match[2], ') ', match[3], '-', match[4], match[5] ? '-' + match[5] : ''].join('');
        }
        return null;
    };

    // eslint-disable-next-line
    const searchWithDebounce = useCallback(debounce(inNetwork, 300), []);

    const searchHandler = (e) => {
        searchWithDebounce(1, network, e.target.value);
    };

    useEffect(() => {
        inNetwork(page, network);
    }, [page, network, storepage,inNetwork]);

    return (
        <>
            <Sidebar />
            <div className="content">
                <div className=" header">
                    <div className="main-container">
                        <div className="top-bar">
                            <div className="inner-container">
                                <div className="top-search-area common">
                                    <h1>Insurance Agencies</h1>
                                </div>
                                <Logout />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container-box padd_hTop">
                        <div className="boxed-area">
                            <div className="boxed-top-bar">
                                <div className="tabbed-links">
                                    <ul>
                                        <li>
                                            <input
                                                name="network"
                                                type="radio"
                                                id="innetwork"
                                                value={1}
                                                onChange={handleChangeTab}
                                                checked={network === 1}
                                            />
                                            <label htmlFor="innetwork">In-Network</label>
                                        </li>

                                        <li>
                                            <input
                                                name="network"
                                                type="radio"
                                                id="outofnetwork"
                                                value={0}
                                                onChange={handleChangeTab}
                                                checked={network === 0}
                                            />
                                            <label htmlFor="outofnetwork">Out-of-Network</label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="search-area">
                                    {/* Search field section start */}
                                    <div className="search-box">
                                        <input
                                            type="search"
                                            placeholder="Search.."
                                            // value={search}
                                            onChange={searchHandler}
                                        />
                                        <span>
                                            <img
                                                src="assets/images/search_icon.png"
                                                alt="search-icon"
                                            />
                                        </span>
                                    </div>
                                    {/* Search field section end */}

                                    <Link to="add_insurance_agency" className="btn">
                                        Add New
                                    </Link>
                                </div>
                            </div>

                            <InNetwork
                                networkResponse={networkResponse}
                                setNetworkResponse={setNetworkResponse}
                                loading={loading}
                                formatPhoneNumber={formatPhoneNumber}
                                page={page}
                                setPage={setPage}
                                network={network}
                                setLoading={setLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Insurence;
