import { Navigate } from "react-router-dom";

import Auth from "../auth/Auth";

const InsurancePublicRoute = ({ children }) => {
    const insuranceToken = Auth.InsuranceToken()
    return (
      insuranceToken ? <Navigate to={"/insurance_claims_list"} /> : children
    );
};

export default InsurancePublicRoute;