import { Navigate } from "react-router-dom";

import Auth from "../auth/Auth";

const PublicRoute = ({ children }) => {

    const token = Auth.token()
    return (
        token ? <Navigate to={"/insurance"} /> : children
    );
};

export default PublicRoute;