import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';

import Spinner from "react-bootstrap/Spinner";
import Dropdown from 'react-bootstrap/Dropdown';

import Auth from "../../auth/Auth";



const Logout = () => {

    const navigate = useNavigate();
    const token = Auth.token();

    // Localstorage get Email section start

    const getEmail = localStorage.getItem("forget_password_email");

    // Localstorage get Email section start

    const [loading, setLoading] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [newPasswordIcon, setNewPasswordIcon] = useState(true);
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(true);

    const passwordPattren = new RegExp("(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).*$");
    const newPasswordCheck = passwordPattren.test(newPassword);

    // Logout API section start
    const handleLogOut = async () => {
        setLoading(true);
        const requestOption = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${token}`
            }
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/logout`, requestOption);
        if (response.status >= 200 || response.status <= 399) {
            const data = await response.json();
            setLoading(true);
            localStorage.setItem("network",1);
            localStorage.setItem("storepage",1);    
            toast.success(data.Success, { autoClose: 2000 })
            navigate("/")
            localStorage.removeItem("token");
        };
        if (response.status >= 400 || response.status <= 500) {
            const error = await response.json();
            toast.error(error.message);
            setLoading(false);
        };
    }

    // Logout API section end

    const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
    const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [password2, setPassword2] = useState(false);

    // Reset Password API section start
    const handleResetPassword = async () => {

        // setLoading(true);
        const resetPasswordKey = {
            email: getEmail,
            password: newPassword,
            password_confirmation: confirmPassword
        };

        const requestOption = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(resetPasswordKey)
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/password-update`, requestOption);

        if (response.status >= 200 && response.status <= 399) {
            const data = await response.json();
            console.log(data);
            // toast.success(data.message)
            setSuccessMessage(true)
            setPassword2(false)
            // setLoading(false);
            setNewPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                navigate("/")
            }, 1000);
        }

        if (response.status >= 400 && response.status <= 500) {
            const errorReset = await response.json();
            console.log(errorReset.message.password[0]);
            // if (errorReset.message.password[0] === "Password must contain 1 uppercase, 1 lowercase, 1 special character and 1 numeric.") {
            //     setStrongPassword(true)
            // }
            // setLoading(false);
            setPassword2(true);
        }

    }
    // Reset Password API section end

    const resetPassword = (e) => {
        e.preventDefault();
        if (!newPassword) {
            setNewPasswordEmpty(true);
        } else if (!confirmPassword) {
            setConfirmPasswordEmpty(true)
            setNewPasswordEmpty(false)
        } else {
            handleResetPassword();
            setNewPasswordEmpty(false)
            setConfirmPasswordEmpty(false)
        }
    };

    // when click cross symbol & cancel button then empty enput field section start
    const handleEmptyInputField = () => {
        setNewPasswordEmpty(false);
        setConfirmPasswordEmpty(false);
        setNewPassword("");
        setConfirmPassword("");

    };
    // when click cross symbol & cancel button then empty enput field section start

    return (
        <>

            <ToastContainer theme="dark" />
            <div className="select-user ">
                <div className="profile-icon">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <span className="user-img">
                                <img src="assets/images/avtar.webp" alt="user-img" onClick={() => setDropDown(!dropDown)} />

                                <span className="arrowicon">
                                    <img src="assets/images/drop_arrow.svg" alt="dropdown_arror" />
                                </span>
                            </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <label onClick={() => setChangePassword(true)}>Change Password</label>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <label onClick={() => setIsModalVisible(true)}>Logout</label>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </div>


            {/* Logout confirm modal section start */}

            <Modal show={isModalVisible} centered onHide={() => setIsModalVisible(false)} className="delete-modal">
                <Modal.Header closeButton>
                    {/* <Modal.Title>Teacher Name List</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>Do you want to logout?</Modal.Body>
                <Modal.Footer>
                    <Button variant="cust" onClick={() => setIsModalVisible(false)}>
                        No
                    </Button>
                    <Button variant="cust" onClick={() => handleLogOut()}>
                        Yes
                    </Button>
                </Modal.Footer>
                {/* Loading section start */}
                {loading ? (
                    <div className="main-loader logout-loader">
                        <Spinner animation="border" className="loader" variant="light" />
                    </div>
                ) : (
                    ""
                )}
                {/* Loading section end */}
            </Modal>


            {/* Change Password modal section start */}
            <Modal show={changePassword} centered onHide={() => { setChangePassword(false); handleEmptyInputField() }} className="delete-modal change_password_modal">
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 px-0">
                        <div className="login-area">
                            <form className="login-form"
                            // onSubmit={(e) => resetPassword(e)}
                            >

                                {/* New Password input field section start */}

                                <div className="form-group form-floating mb-5">
                                    <input
                                        type={newPasswordIcon ? "password" : "text"}
                                        className="form-control"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <label htmlFor="password">New Password</label>

                                    <span className="eye-view trash_pointer">
                                        {!newPasswordIcon ? <img src="assets/images/eye_view.png" onClick={() => setNewPasswordIcon(!newPasswordIcon)} alt="eye-view" /> :
                                            <img src="assets/images/eye_slash.svg" onClick={() => setNewPasswordIcon(!newPasswordIcon)} alt="eye-view" />}
                                    </span>

                                    <div className="newPassword">

                                        {/* Response Validation section start */}
                                        <span className="newPassword">{(newPasswordEmpty && newPassword.length <= 0) ? <span>Please enter the new password</span> : ""}</span>

                                        {((!newPasswordCheck && newPassword.length !== 0) || (newPassword.length < 8 && newPassword.length !== 0)) && <span className="newPassword">Password must contain atleast 8 characters with a mix of letters,numbers and symbol.</span>}
                                    </div>
                                    {/* Response Validation section end */}


                                </div>

                                {/* New Password input field section end */}

                                {/* Confirm Password input field section start */}

                                <div className="form-group form-floating">
                                    <input
                                        type={confirmPasswordIcon ? "password" : "text"}
                                        className="form-control"
                                        placeholder="Confirm New Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    /><label htmlFor="new-password">Confirm new password</label>
                                    <span className="eye-view trash_pointer">
                                        {!confirmPasswordIcon ? <img src="assets/images/eye_view.png" onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)} alt="eye-view" /> :
                                            <img src="assets/images/eye_slash.svg" onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)} alt="eye-view" />}
                                    </span>
                                </div>
                                {/* Confirm New password validation show message section start */}
                                <div className="newPassword">{password2 ? <span></span> : ""}

                                    <span className="newPassword">{(confirmPassword.length > 0 && confirmPassword.length === "") ? <span>Passwords must be same </span> : (((newPassword) !== confirmPassword) && confirmPassword.length > 0) ? <span>Passwords must be same</span> : ""}</span>

                                    {(confirmPasswordEmpty && confirmPassword.length <= 0) ? <span className="newPassword">Please enter the confirm password</span> : ""}

                                    {/* Confirm New password validation show message section end */}
                                    {successMessage ? <span className="success_message">Password changed successfully</span> : ""}

                                </div>

                                {/* Confirm Password input field section end */}

                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="cust" onClick={() => {
                        setChangePassword(false)
                        handleEmptyInputField();
                    }}>
                        Cancel
                    </Button>
                    <Button variant="cust"
                        onClick={(e) => resetPassword(e)}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal >

            {/* Change Password modal section end */}

        </>

    )
};
export default Logout;