import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";

import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Carousel from "react-bootstrap/Carousel";

import Auth from "../../auth/Auth";
import Logout from "../onboarding/Logout";
import Sidebar from "../../sidebar/Sidebar";

import "bootstrap/dist/css/bootstrap.css";

const ClaimDetails = () => {
  const { id } = useParams();

  const { values, setInitialValues } = useForm({
    validations: {},
  });

  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOther, setIsModalOther] = useState(false);
  const [isModalVin, setIsModalVin] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const navigate = useNavigate();

  const handleClaimById = async () => {
    setLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Accept: "Application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/claim?id=${id}`,
      requestOption
    );
    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setInitialValues(data?.claimList);
      console.log(data, "dtaaa");
      // data?.claimList?.damage_image?.map((data)=>(data.image))

      //     fetch(`${process.env.REACT_APP_BASE_URL}/images/${}`).then(resp => resp.blob())
      //     .then(blob => {
      //       console.log(blob.size, '>>>>');
      //       });

      console.log(data?.claim_accident_info, "dadadad");
      setLoader(false);
    }
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      setLoader(false);
      toast.error(error?.message);
    }
  };

  // Number split formating "+1 (234) 567-8900" section start
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(2|)?(\d{3})(\d{3})(\d{4})(\d{0,5})$/);
    // console.log('match',match);
    if (match) {
      // var intlCode = (match[1] ? '+1 ' : '');
      // return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
      return [
        "(",
        match[1],
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
        match[5] ? "-" + match[5] : "",
      ].join("");
    }
    return null;
  }

  useEffect(() => {
    handleClaimById();
    // eslint-disable-next-line
  }, []);

  //   Handle modal Image show index
  const handleImage = (id) => {
    setImageIndex(id);
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <div className=" header">
          <div className="main-container">
            <div className="top-bar">
              <div className="inner-container">
                <div className="top-search-area common">
                  <h1>Claims</h1>
                </div>
                <Logout />
              </div>
            </div>
          </div>
        </div>
        <div className="container-box main-container padd_hTop">
          <div className="boxed-area">
            <div className="boxed-top-bar exPad">
              <div className="tabbed-links">
                <span className="back-icon">
                  <Link to="/claims_list">
                    <img
                      src="assets/images/arrow_header.svg"
                      alt="arrow-header"
                    />
                  </Link>
                </span>
                <h2>Claim Detail</h2>
              </div>
            </div>
            {/* <!-- Boxed Top Bar Ends Here --> */}
            <div className="boxed-container">
              {/* Personal Info section start*/}

              <div className="details-box">
                <div className="detail-head">
                  <h3>Personal Info</h3>
                </div>
                <div className="details-body row">
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Full Name</p>
                      <p className="sub-text">
                        {loader ? <div className="loader"></div> : values?.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Insurance Agency</p>
                      {values?.insurance_agency_id?.map((tit, index) => {
                        return (
                          <p className="sub-text" key={index}>
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              tit?.title
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Phone No.</p>
                      <p className="sub-text">
                        {loader ? (
                          <div className="loader"></div>
                        ) : (
                          formatPhoneNumber(values?.phone_number)
                        )}
                      </p>
                      {/* {values?.insurance_agency_id?.map((phone, index) => {
                                                return (
                                                )
                                            })} */}
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Email</p>
                      <p className="sub-text">
                        {loader ? <div className="loader"></div> : values.email}
                      </p>
                      {/* {values?.insurance_agency_id?.map((emai, index) => {
                                                return (
                                                )
                                            })} */}
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-8">
                    <div className="detail-cards">
                      <p className="sub-heading">Address</p>
                      <p className="sub-text">
                        {loader ? (
                          <div className="loader"></div>
                        ) : (
                          values?.address
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Policy Number</p>
                      <p className="sub-text">
                        {loader ? (
                          <div className="loader"></div>
                        ) : (
                          values?.policy_number
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Claim Number</p>
                      <p className="sub-text">
                        {loader ? <div className="loader"></div> : id}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Method of Contact</p>
                      <p className="sub-text telephone">
                        {loader ? (
                          <div className="loader"></div>
                        ) : (
                          values?.Method_of_contact
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Personal Info section end*/}

              {/* Accident Information section start */}

              <div className="details-box">
                <div className="detail-head">
                  <h3>Accident Information</h3>
                </div>
                <div className="details-body row">
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Date of Loss</p>
                      {values?.claim_accident_info?.map((creat, index) => {
                        return (
                          <p key={index} className="sub-text">
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              moment(creat?.date_of_loss).format("MMM DD, YYYY")
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Location of Loss</p>
                      {values?.claim_accident_info?.map((loca, index) => {
                        return (
                          <p className="sub-text location-text" key={index}>
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                                loca?.location_of_loss
                              )}`}
                              target="_blank"
                              className="sub-text theme-color text-decoration-underline"
                              rel="noreferrer"
                            >
                              <img
                                src="assets/images/blue_map.svg"
                                className="pe-2"
                                alt="blue_map"
                              />
                              {loader ? (
                                <div className="loader"></div>
                              ) : loca?.location_of_loss?.split(",").slice(0, 3)
                                  ?.length >= 3 ? (
                                `${loca?.location_of_loss
                                  ?.split(",")
                                  .slice(0, 3)} ....`
                              ) : (
                                loca?.location_of_loss?.split(",").slice(0, 3)
                              )}
                            </a>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Driver Full Name</p>
                      {values?.claim_accident_info?.map((drive, index) => {
                        return (
                          <p key={index} className="sub-text">
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              drive?.driver_full_name
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Vehicle</p>
                      {values?.claim_accident_info?.map((vehi, index) => {
                        return (
                          <p key={index} className="sub-text">
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              vehi?.vehicle
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div> */}
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Vehicle</p>
                      {values?.claim_accident_info?.map((vehi, index) => {
                        return (
                          <p key={index} className="sub-text">
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              vehi?.vehicle_year
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">State of Accident</p>
                      {values?.claim_accident_info?.map((sta, index) => {
                        return (
                          <p key={index} className="sub-text">
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              sta?.states?.name
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="detail-cards">
                      <p className="sub-heading">Was Anyone Injured?</p>
                      {values?.claim_accident_info?.map((any, index) => {
                        return (
                          <p key={index} className="sub-text">
                            {loader ? (
                              <div className="loader"></div>
                            ) : any?.anyone_injured === 1 ? (
                              "Yes"
                            ) : (
                              "No"
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-xl-4">
                      <div className="detail-cards">
                        <p className="sub-heading">Name of Injured Party</p>
                        {values?.claim_accident_info?.map((data, index) => {
                          const { injured_first_name, injured_last_name } =
                            data;
                          return (
                            <p key={index} className="sub-text">{`${
                              loader ? (
                                <div className="loader"></div>
                              ) : injured_first_name + injured_last_name ===
                                0 ? (
                                "N/A"
                              ) : (
                                injured_first_name + ` ` + injured_last_name
                              )
                            }`}</p>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-col-12">
                    <div className="detail-cards">
                      <p className="sub-heading">
                        Brief Description of Injuries
                      </p>
                      {values?.claim_accident_info?.map((inju_des, index) => {
                        // debugger
                        return (
                          <p className="sub-text" key={index}>
                            {loader ? (
                              <div className="loader"></div>
                            ) : inju_des?.Injury_description === null ? (
                              "N/A"
                            ) : (
                              inju_des?.Injury_description
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-col-12">
                    <div className="detail-cards">
                      <p className="sub-heading">
                        Description of Loss/Accident
                      </p>
                      {values?.claim_accident_info?.map((acci_des, index) => {
                        return (
                          <p className="sub-text" key={index}>
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              acci_des?.Accident_description
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              {/* Accident Information section end */}

              {/* Images of Damage  section start*/}
              <div className="details-box">
                <div className="detail-head">
                  <h3>Images of Damage</h3>
                </div>
                <div className="details-body row">
                  {values?.damage_image?.length > 0 ? (
                    values?.damage_image?.map((damageImage, index) => {
                      const { image, position, notes } = damageImage;
                      return (
                        <div
                          key={index}
                          className="col-md-6 col-xl-4 col-xxl-3"
                        >
                          <div
                            className="detail-cards image-card"
                            onClick={() => {
                              setIsModalVisible(true);
                              handleImage(index);
                            }}
                          >
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                                className="mb-4"
                                alt="img01"
                                id="img"
                              />
                            )}
                            <div className="card-cont">
                              <p className="sub-heading position">{`${
                                position === null ? `N/A` : `${position}`
                              }`}</p>
                              {console.log(values.lat, ">>>>")}
                              {/* <p className="sub-text">{image_type}</p> */}
                              <p className="sub-text sub-text-comments">
                                {!notes ? "No comments." : notes}
                              </p>
                              {/* <p className="sub-text">
                                {values.lat
                                  ? values.lat?.toFixed(4)
                                  : "30.1234"}
                                ° N,{" "}
                                {values.long
                                  ? values.long?.toFixed(4)
                                  : "76.1234"}
                                ° E
                              </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <b className="not_applicable">N/A</b>
                  )}

                  <div className="col-md-6 col-xl-4 col-xxl-3"></div>
                </div>
              </div>

              {/* Images of Damage  section end*/}

              {/* Images of other vehicle  section start*/}

              <div className="details-box">
                <div className="detail-head">
                  <h3>Images of other vehicle</h3>
                </div>
                <div className="details-body row">
                  {values?.other_image?.length > 0 ? (
                    values?.other_image?.map((otherImage, index) => {
                      const { image, position, notes } = otherImage;
                      return (
                        <div
                          key={index}
                          className="col-md-6 col-xl-4 col-xxl-3"
                        >
                          <div
                            className="detail-cards image-card"
                            onClick={() => {
                              setIsModalOther(true);
                              handleImage(index);
                            }}
                          >
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              <img
                                alt="img05"
                                src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                                className="mb-4"
                              />
                            )}
                            <div className="card-cont">
                              <p className="sub-heading position">{`${
                                position === null ? `N/A` : `${position} Side`
                              }`}</p>
                              {/* <p className="sub-text">{image_type}</p> */}
                              <p className="sub-text sub-text-comments">
                                {!notes ? "No comments." : notes}
                              </p>
                              {/* <p className="sub-text">
                                {values.lat
                                  ? values.lat?.toFixed(4)
                                  : "30.1234"}
                                ° N,{" "}
                                {values.long
                                  ? values.long?.toFixed(4)
                                  : "76.1234"}
                                ° E
                              </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <b className="not_applicable">N/A</b>
                  )}
                  <div className="col-md-6 col-xl-4 col-xxl-3"></div>
                </div>
              </div>

              {/* Images of other vehicle  section end*/}

              {/* Images of VIN / SERIAL Number section start */}
              <div className="details-box">
                <div className="detail-head">
                  <h3>Images of VIN / SERIAL Number</h3>
                </div>
                {values?.vin_image?.length > 0 ? (
                  values?.vin_image?.map((vinImage, index) => {
                    const { image, position, notes } = vinImage;
                    return (
                      <div key={index} className="details-body row">
                        <div className="col-md-6 col-xl-4 col-xxl-3">
                          <div
                            className="detail-cards image-card"
                            onClick={() => {
                              setIsModalVin(true);
                              handleImage(index);
                            }}
                          >
                            {loader ? (
                              <div className="loader"></div>
                            ) : (
                              <img
                                alt="img06"
                                src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                                className="mb-4"
                              />
                            )}
                            <div className="card-cont">
                              {/* <p className="sub-heading position">{`${position}` ? `${position} Side`: `N/A` }</p> */}
                              <p className="sub-heading position">{position}</p>
                              {/* <p className="sub-text">{image_type}</p> */}
                              <p className="sub-text sub-text-comments">
                                {!notes ? "No comments." : notes}
                              </p>
                              {/* <p className="sub-text">
                                {values.lat
                                  ? values.lat?.toFixed(4)
                                  : "30.1234"}
                                ° N,{" "}
                                {values.long
                                  ? values.long?.toFixed(4)
                                  : "76.1234"}
                                ° E
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                    );
                  })
                ) : (
                  <b className="not_applicable">N/A</b>
                )}
              </div>
              {/* Images of VIN / SERIAL Number section end */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal section start */}
      <Modal
        show={isModalVisible}
        centered
        onHide={() => setIsModalVisible(false)}
        className="image-modal"
      >
        {/* Crousal section start */}
        <Modal.Header className="modal-header-claim" closeButton></Modal.Header>
        <Carousel
          slide="false"
          indicators={false}
          defaultActiveIndex={imageIndex}
        >
          {values?.damage_image?.length > 0 ? (
            values?.damage_image?.map((claimImage, index) => {
              const { image, position, notes } = claimImage;
              return (
                <Carousel.Item key={index} className="carousel-item-claim">
                  <h5 className="sub-heading position position-claim">{`${
                    position === null ? `N/A` : `${position} Side`
                  }`}</h5>
                  <div className="crousal_image">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                      className="mb-4"
                      alt="img01"
                    />
                  </div>

                  <Carousel.Caption>
                    <div className="card-cont">
                      <h4 className="sub-heading">Notes</h4>
                      <p className="sub-text">
                        <span className="img_count">
                          {index + 1} / {values.damage_image?.length}
                        </span>
                        {!notes ? "N/A" : notes}
                      </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          ) : (
            <b>Image not found</b>
          )}
        </Carousel>
      </Modal>

      {/* Modal Images of other vehicle section start */}
      <Modal
        show={isModalOther}
        centered
        onHide={() => setIsModalOther(false)}
        className="image-modal"
      >
        {/* Crousal section start */}
        <Modal.Header closeButton></Modal.Header>
        <Carousel
          slide="false"
          indicators={false}
          defaultActiveIndex={imageIndex}
        >
          {values?.other_image?.length > 0 ? (
            values?.other_image?.map((claimImage, index) => {
              const { image, position, notes } = claimImage;
              return (
                <Carousel.Item key={index}>
                  <h5 className="sub-heading position">{`${
                    position === null ? `N/A` : `${position} Side`
                  }`}</h5>
                  <div className="crousal_image">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                      className="mb-4"
                      alt="img01"
                    />
                  </div>
                  <Carousel.Caption>
                    <div className="card-cont">
                      <h4 className="sub-heading">Notes</h4>
                      <p className="sub-text">
                        <span className="img_count">
                          {index + 1}/{values?.other_image?.length}
                        </span>
                        {!notes ? "N/A" : notes}
                      </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          ) : (
            <b>Image not Found</b>
          )}
        </Carousel>
      </Modal>

      {/* Modal VIN section start */}
      <Modal
        show={isModalVin}
        centered
        onHide={() => setIsModalVin(false)}
        className="image-modal"
      >
        {/* Crousal section start */}
        <Modal.Header closeButton></Modal.Header>
        <Carousel
          slide="false"
          indicators={false}
          defaultActiveIndex={imageIndex}
        >
          {values?.vin_image?.length > 0 ? (
            values?.vin_image?.map((claimImage, index) => {
              const { image, position, notes } = claimImage;
              return (
                <Carousel.Item key={index}>
                  <h5 className="sub-heading position">{position}</h5>
                  {/* <h5 className="sub-heading position">VIN/Serial Number Image</h5> */}
                  <div className="crousal_image">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
                      className="mb-4"
                      alt="img01"
                    />
                  </div>
                  <Carousel.Caption>
                    <div className="card-cont">
                      <h4 className="sub-heading">Notes</h4>
                      <p className="sub-text">
                        <span className="img_count">
                          {index + 1}/{values?.vin_image?.length}
                        </span>
                        {!notes ? "N/A" : notes}
                      </p>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })
          ) : (
            <b>Image not Found</b>
          )}
        </Carousel>
      </Modal>

      {/* Loading section start */}
      {loader ? (
        <div className="main-loader">
          <Spinner animation="border" className="loader" variant="light" />
        </div>
      ) : (
        ""
      )}

      {/* <div className="loader"></div> */}
    </>
  );
};
export default ClaimDetails;
