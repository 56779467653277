import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import Spinner from "react-bootstrap/Spinner";

const ResetPassword = () => {

    // Localstorage get Email section start

    const getEmail = localStorage.getItem("forget_password_email");

    // Localstorage get Email section start


    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPasswordIcon, setNewPasswordIcon] = useState(true);
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(true);

    const passwordPattren = new RegExp("(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=]).*$");
    const newPasswordCheck = passwordPattren.test(newPassword);
    // const confirmPasswordCheck = passwordPattren.test(confirmPassword);

    const navigate = useNavigate();

    // Reset Password API section start

    const handleResetPassword = async () => {
        setLoading(true);
        const resetKey = {
            email: getEmail,
            password: newPassword,
            password_confirmation: confirmPassword
        };

        const requestResetPassword = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(resetKey)
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/password-update`, requestResetPassword);
        if (response.status >= 200 && response.status <= 399) {
            const data = await response.json();
            toast.success(data.message)
            setLoading(false);
            setNewPassword("");
            setConfirmPassword("");
            setTimeout(() => {
                navigate("/")
            }, 1000);
        };
        if (response.status >= 400 && response.status <= 500) {
            const errorReset = await response.json();
            setLoading(false);
            toast.error(errorReset.message.password[0]);
            toast.error(errorReset.message.password[1]);
            toast.error(errorReset.message.password_confirmation[0]);
            toast.error(errorReset.message.password_confirmation[1]);
        }

    }
    // Reset Password API section end


    // Validation and API hit functio start

    const resetPassword = (e) => {
        e.preventDefault();
        if (!newPassword) {
            toast.error("Please enter the new password");
        } else if (!confirmPassword) {
            toast.error("Please enter the confirm password")
        } else {
            handleResetPassword();
        }
    };
    // Validation and API hit functio end

    return (
        <>
            <div id="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-lg-5 col-xl-6 px-0">
                            <div className="doc-area">
                                <div className="logo">
                                    <img src="assets/images/big-rig-logo.svg" alt="big-rig-logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-7 col-xl-6 px-0">
                            <div className="login-area">
                                <h1>Reset Password</h1>
                                <form className="login-form" onSubmit={(e) => resetPassword(e)}>

                                    {/* New Password input field section start */}

                                    <div className="form-group form-floating mb-5">
                                        <input
                                            type={newPasswordIcon ? "password" : "text"}
                                            className="form-control"
                                            placeholder="New Password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        /><label htmlFor="password">New Password</label>

                                        <span className="eye-view trash_pointer">
                                            {!newPasswordIcon ? <img src="assets/images/eye_view.png" onClick={() => setNewPasswordIcon(!newPasswordIcon)} alt="eye-view" /> :
                                                <img src="assets/images/eye_slash.svg" onClick={() => setNewPasswordIcon(!newPasswordIcon)} alt="eye-view" />}
                                        </span>

                                        <div className="newPassword">
                                            {((!newPasswordCheck && newPassword.length !== 0) || (newPassword.length < 8 && newPassword.length !== 0)) ? <span className="newPassword">Password must contain atleast 8 characters including one lowercase,one uppercase,one numeric digit and one special character!</span> : ""}
                                        </div>
                                    </div>

                                    {/* New Password input field section end */}

                                    {/* Confirm Password input field section start */}

                                    <div className="form-group mb-4 form-floating">
                                        <input
                                            type={confirmPasswordIcon ? "password" : "text"}
                                            className="form-control"
                                            placeholder="Confirm New Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        /><label htmlFor="new-password">Confirm New Password</label>

                                        <span className="eye-view trash_pointer">
                                            {!confirmPasswordIcon ? <img src="assets/images/eye_view.png" onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)} alt="eye-view" /> :
                                                <img src="assets/images/eye_slash.svg" onClick={() => setConfirmPasswordIcon(!confirmPasswordIcon)} alt="eye-view" />}
                                        </span>
                                        <span className="newPassword">{(confirmPassword.length > 0 && confirmPassword.length === "") ? <span>Passwords must be same </span> : (((newPassword) !== confirmPassword) && confirmPassword.length > 0) ? <span>Passwords must be same</span> : ""}</span>
                                    </div>

                                    {/* Confirm Password input field section end */}

                                    <button type="submit" className="btn theme-btn"
                                    >Done</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="dark" />

            {/* Loading section start */}
            {loading ? (
                <div className="main-loader">
                    <Spinner animation="border" className="loader" variant="light" />
                </div>
            ) : (
                ""
            )}
            {/* Loading section end */}
        </>
    )
};
export default ResetPassword;