import React, { useCallback, useEffect, useState } from "react";

import Pagination from "../pagination/Pagination";

import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Auth from "../../auth/Auth";
import { toast } from "react-toastify";
import Sidebar from "../../sidebar/Sidebar";
import Logout from "../onboarding/Logout";

function debounce(func, timeout = 500) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const UserInfo = ({
  // networkResponse,
  // setNetworkResponse,
  // loading,
  // page,
  // setPage,
  // setLoading,

  network,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [order, setOrder] = useState("ASC");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(25);

  const [networkResponse, setNetworkResponse] = useState([]);
  const [pages, setPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [allData, setAllData] = useState({});

  const navigate = useNavigate();

  const handleDeleteModal = (id, linked) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const limit = 25;

  // const inNetwork = useCallback(async (page, search, network = 1) => {
  //   // setLoading(true);
  //   const requestOption = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${Auth.token()}`,
  //     },
  //   };
  //   const res = await fetch(
  //     `${
  //       process.env.REACT_APP_BASE_URL
  //     }/api/user-list?limit=${limit}&page=${1}&search=${
  //       search === undefined ? "" : search
  //     }&userInfo=${network}`,
  //     requestOption
  //   );
  //   if (res.status >= 200 && res.status <= 399) {
  //     let data = await res.json();
  //     setNetworkResponse(data?.claimList);
  //     setUserData(data?.claimList?.data);
  //     console.log(data?.claimList);
  //     //   setLoading(false);
  //   }
  //   if (res.status === 401) {
  //     localStorage.removeItem("token");
  //     //   navigate("/");
  //   }
  //   if (res.status >= 400 && res.status <= 500) {
  //     const error = await res.json();
  //     toast.error(error?.message);
  //     //   setLoading(false);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const inNetwork = useCallback(async (page, pages, search, network = 1) => {
    setLoading(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/user-list?limit=${
        page > 25 ? page : 25
      }&page=${pages ? pages : 1}&search=${
        search === undefined ? "" : search
      }&userInfo=${network}`,
      requestOption
    );
    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      // const newList = [ ...data?.claimList?.data];
      setData(data?.claimList?.data);

      // setPageSize(data?.claimList?.data?.length);
      setAllData(data?.claimList);
      setLoading(false);
    }
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const searchWithDebounce = useCallback(debounce(inNetwork, 300), []);

  const searchHandler = (e) => {
    setSearchData(e.target.value);
    setCurrentPage();
    searchWithDebounce("", 1, e.target.value, network);
  };

  useEffect(() => {
    inNetwork("", pages, currentPage, network);
  }, [pages, inNetwork, network, currentPage]);

  // Dlete Insurance Agency
  const deleteInsurance = async (id) => {
    console.log(id, "idididi");
    setLoading(true);
    const requestKey = {
      id: id,
    };
    const requestOption = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
      //   body: JSON.stringify(requestKey),
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/user-delete/${id}`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      console.log(data, "asdad");
      toast.success(data?.message, { autoClose: 2000 });
      setLoading(false);
      setIsModalVisible(false);
      inNetwork();
    }
    if (res.status === 401) {
      alert("done");
      //   localStorage.removeItem("token");
      //   navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setLoading(false);
    }
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      );
      console.log(sorted, "userData");
      setData(sorted);
      setOrder("ASC");
    }
  };

  const _pageSize = (pages) => {
    setPageSize(pages);
    inNetwork(pages, 1, "");
    //   setAllData(allData?.data?.length)
    // setPage(pages)
  };

  console.log(data, "kakkaka");

  return (
    <>
      <Sidebar />
      <div className="content">
        <div className=" header">
          <div className="main-container ">
            <div className="top-bar">
              <div className="inner-container">
                <div className="top-search-area common">
                  <h1>Users</h1>
                </div>
                <Logout />
              </div>
            </div>
          </div>
        </div>
        <div className="container-box padd_hTop">
          <div className="boxed-area">
            <div className="boxed-top-bar exPad">
              <div className="tabbed-links">
                <span>Users List</span>
                {/* <ul>
                                        <li>
                                            <input
                                                name="network"
                                                type="radio"
                                                id="innetwork"
                                                onChange={() => {setNetwork(0);setPage(1)}}
                                                checked={network === 0}
                                            />
                                            <label htmlFor="innetwork">User Info</label>
                                        </li>

                                        <li>
                                            <input
                                                name="network"
                                                type="radio"
                                                id="outofnetwork"
                                                onChange={() => {setNetwork(1);setPage(1)}}
                                                checked={network === 1}
                                            />
                                            <label htmlFor="outofnetwork">Saved Profile</label>
                                        </li>
                            </ul> */}
              </div>
              {/*  Search section start*/}
              <div className="search-area">
                {/* Search field section start */}
                <div className="search-box">
                  <input
                    type="search"
                    placeholder="Search.."
                    value={searchData}
                    onChange={searchHandler}
                  />
                  <span>
                    <img
                      src="assets/images/search_icon.png"
                      alt="search-icon"
                    />
                  </span>
                </div>
                {/* Search field section end */}
              </div>
              {/*  Search section start*/}
            </div>
            <div className="table-responsive rig-table">
              <table className="table">
                <thead>
                  <tr>
                    <th className="serialTh">S.No.</th>
                    <th className="logoTh">
                      Date
                      <img
                        src="assets/images/sorting.png"
                        onClick={() => sorting("created_at")}
                        className="sorting_img"
                        alt="sorting"
                      />
                    </th>
                    {/* {network===1 && <th className="logoTh">Name</th>} */}
                    <th className="logoTh">
                      Name
                      <img
                        src="assets/images/sorting.png"
                        onClick={() => sorting("name")}
                        className="sorting_img"
                        alt="sorting"
                      />
                    </th>
                    <th className="emailTh">Email</th>
                    <th className="websiteTh">Insurance Provider</th>
                    <th className="agencyTh">Policy Number</th>
                    <th className="actionTh">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData?.data?.length > 0 ? (
                    data?.map((userDatas, index) => {
                      const {
                        created_at,
                        name,
                        email,
                        insurance_agency_id,
                        policy_number,
                        id,
                      } = userDatas;
                      return (
                        <tr key={index}>
                          {console.log(userDatas, "userData")}
                          <td>{allData?.from + index}</td>
                          <td>{moment(created_at).format("MMM DD, YYYY")}</td>
                          {/* {network===1 &&<td>{!name ? "N/A" :name }</td>} */}
                          <td>{!name ? "N/A" : name}</td>
                          <td>{email}</td>
                          <td>
                            {console.log(userDatas?.user_profile, "qwqw")}
                            {!userDatas?.user_profile[0]?.insurance_agency_id
                              ?.title
                              ? "N/A"
                              : userDatas?.user_profile[0]?.insurance_agency_id
                                  ?.title}
                            {/* {userDatas?.user_profile?.map((data, index) => (
                              <div>
                                {
                                  console.log(data, '>>>')
                                }
                                {(index ? "" : "") +
                                  data[0]?.insurance_agency_id?.title}
                              </div>
                            ))} */}
                          </td>
                          <td>
                            {" "}
                            {userDatas?.user_profile[0]?.policy_number}
                            {/* {userDatas?.user_profile?.map((data, index) => (
                              <div>
                                {(index ? "" : "") + data?.policy_number}
                              </div>
                            ))} */}
                          </td>
                          <td>
                            <div className="edit-area">
                              <span>
                                <Link to={`users_details/${id}`}>
                                  <img
                                    src="assets/images/blue_eye.svg"
                                    alt="view-icon"
                                  />
                                </Link>
                              </span>
                              <span className="trash_pointer">
                                <img
                                  src="assets/images/trash.svg"
                                  alt="trash"
                                  onClick={() => handleDeleteModal(id)}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : !loading ? (
                    <tr>
                      <td colSpan="10" align="center" className="mt-5">
                        <h1>Data not found</h1>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>

              <div className="pagination-footer row m-0">
                <div className="pagination-area col-sm-6">
                  <div className="page-counter">
                    <span className="first">
                      {loader ? (
                        <div className="loader"></div>
                      ) : !allData?.from ? (
                        1
                      ) : (
                        allData?.from
                      )}
                    </span>
                    -
                    <span className="second">
                      {loader ? (
                        <div className="loader"></div>
                      ) : !allData?.to ? (
                        0
                      ) : (
                        allData?.to
                      )}
                    </span>{" "}
                    of{" "}
                    {loader ? <div className="loader"></div> : allData?.total}{" "}
                  </div>
                  <div className="pagination"></div>
                </div>
                <div className="dash_pagination col-sm-6">
                  <nav aria-label="Page navigation example">
                    <Pagination
                      // lastPage={allData.last_page}
                      totalPages={allData?.last_page}
                      currentPage={allData?.current_page}
                      page={pages}
                      setPage={(page) => setPage(page)}
                    />
                    <div className="select-box">
                      {" "}
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          _pageSize(Number(e.target.value));
                        }}
                      >
                        {[25, 50, 100, 150, 200].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Loading section start */}
      {loading ? (
        <div className="main-loader">
          <Spinner animation="border" className="loader" variant="light" />
        </div>
      ) : (
        ""
      )}
      {/* Delete modal section start */}

      <Modal
        show={isModalVisible}
        centered
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Teacher Name List</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="trash-img">
            <img src="assets/images/trash.svg" alt="trash" />
          </div>
          Do you want to delete?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cust" onClick={() => setIsModalVisible(false)}>
            No
          </Button>
          <Button
            variant="cust"
            onClick={() => {
              deleteInsurance(deleteId);
              setIsModalVisible(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default UserInfo;
