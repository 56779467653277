class Auth {
  authenticated = false;

  login(data) {
    const token = data.data.token;
    localStorage.setItem("token", token);
    // const user = JSON.stringify(data);
    // localStorage.setItem('_leangoUser', user);

    if (token) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  Insurancelogin(data) {
    console.log(data , 'sadad');
    const insuranceToken = data?.data?.token;
    localStorage.setItem("insuranceToken", insuranceToken);

    const user = JSON.stringify(data?.data?.user);
    localStorage.setItem('agency_login', user);

    if (insuranceToken) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  // user() {
  //     let user = JSON.parse(localStorage.getItem('_leangoUser'));
  //     return user.data.user;
  // }
  token() {
    const token = localStorage.getItem("token");
    return token;
  }

  InsuranceToken() {
    const insuranceToken = localStorage.getItem("insuranceToken");
    return insuranceToken;
  }


  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("insuranceToken");
    localStorage.removeItem("forget_password_email");
    localStorage.removeItem('insurance_forget_password_email')
    // localStorage.removeItem('_leangoUser');
    this.authenticated = false;
  }

  isUserAuthenticated() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }
}
export default new Auth();
