import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import Spinner from "react-bootstrap/Spinner";

import Auth from "../../auth/Auth";

const Login = () => {

    const emailTest =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [emails, setEmails] = useState("");
    const [passwords, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordIcon, setPasswordIcon] = useState(true);

    localStorage.setItem("forget_password_email", emails)

    const navigate = useNavigate();

    //  Login API section start

    const logIn = async () => {
        setLoading(true);
        const loginKey = {
            email: emails,
            password: passwords
        };

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(loginKey)
        }
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, requestOptions);
        if (response.status >= 200 && response.status <= 399) {
            const data = await response.json();
            Auth.login(data);
            setLoading(false);
            setEmails("");
            setPassword("");
            navigate("/insurance");
        }
        if (response.status >= 400 && response.status <= 500) {
            const error = await response.json();
            toast.error(error.message);
            setLoading(false);
        }
    }

    //  Login API section end

    const handleLogin = (e) => {
        e.preventDefault();
        if (!emails) {
            toast.error("Please enter the email");
        } else if (!emailTest.test(emails)) {
            toast.error("Invalid email");
        } else if (!passwords) {
            toast.error("Please enter the password");
        } else if (passwords.indexOf(" ") >= 0) {
            toast.error("Space is not allowed in password field");
        } else {
            logIn();
        }
    };

    return (
        <div id="wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-lg-5 col-xl-6 px-0 onboarding-left">
                        <div className="doc-area">
                            <div className="logo">
                                <img src="assets/images/big-rig-logo.svg" alt="big-rig-logo" />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-7 col-xl-6 px-0">
                        <div className="login-area">
                            <h1>Sign In</h1>
                            <form className="login-form" onSubmit={(e) => handleLogin(e)}>
                                <div className="form-group form-floating mb-5">
                                    <input
                                        type="text"
                                        value={emails}
                                        className="form-control"
                                        placeholder="Email / Username"
                                        onChange={(e) => setEmails(e.target.value)}
                                    />
                                    <label htmlFor="email">Email / Username</label>
                                </div>
                                <div className="form-group form-floating mb-4">
                                    <input
                                        type={passwordIcon ? "password" : "text"}
                                        value={passwords}
                                        className="form-control"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className="eye-view trash_pointer">
                                        {!passwordIcon ? <img src="assets/images/eye_view.png" onClick={() => setPasswordIcon(!passwordIcon)} alt="eye-view" /> :
                                            <img src="assets/images/eye_slash.svg" onClick={() => setPasswordIcon(!passwordIcon)} alt="eye-view" />}
                                    </span>
                                    <label htmlFor="password" >Password</label>
                                </div>
                                <p className="forgot">
                                    <Link to="/forget_password">Forgot Password?</Link>
                                </p>
                                <button type="submit" className="btn theme-btn">Sign In</button>
                            </form>
                            <p className="insurance-link">
                                    Login as an<Link to="/insurance_agency_login">Insurance Agency</Link>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="dark" />
            {/* Loading section start */}
            {loading ? (
                <div className="main-loader">
                    <Spinner animation="border" className="loader" variant="light" />
                </div>
            ) : (
                ""
            )}
        </div>
    )
};
export default Login;