import { Navigate } from "react-router-dom";

import Auth from "../auth/Auth";

const InsurancePrivateRoute = ({ children }) => {
    const insuranceToken = Auth.InsuranceToken()

    return (
      insuranceToken ? children : <Navigate to={"/insurance_agency_login"} />
    );
};

export default InsurancePrivateRoute;