import React, { useState, useEffect, useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";

import moment from "moment";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

import Auth from "../../auth/Auth";
import Logout from "../onboarding/Logout";
import Sidebar from "../../sidebar/Sidebar";

import Pagination from "../pagination/Pagination";

const limit = 2;

const getNetwork = localStorage.getItem("network");
const storepage = localStorage.getItem("storepage");

const convertNumber = Number(getNetwork);
const convertNumberPage = Number(storepage);

function debounce(func, timeout = 500) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const ClaimsList = () => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [pages, setPage] = useState(1);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loader, setLoader] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [order, setOrder] = useState("ASC");
  const [pageSize, setPageSize] = useState(25);

  const [pageResponse, setPageResponse] = useState({ pageCount: "", total: 1 });

  const [allData, setAllData] = useState({});
  const [deleteId, setDeleteId] = useState("");

  const navigate = useNavigate();

  const columns = [
    {
      title: "S.No.",
      dataIndex: "id",
      width: "20%",
    },

    {
      title: "Date",
      dataIndex: `'updated_at`,

      render: (text, record) => (
        <>
          <span>{moment(record.updated_at).format("MMM DD, YYYY")}</span>
        </>
      ),
    },
    {
      title: "Insurance Agency",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title?.length - b.title?.length,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => (
        <>
          <span>
            {record.insurance_agency_id.map((title, index) => {
              return (
                <div key={index}>
                  <span>{title.title}</span>
                </div>
              );
            })}
          </span>
        </>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "name",
    },
    {
      title: "Phone No.",
      // dataIndex: `${formatPhoneNumber('phone_number')}`,
      dataIndex: `phone_number`,
      render: (text, record) => (
        <>
          <span>
            {record.phone_number?.toString()?.length < 10 ? (
              <td>Invalid Number</td>
            ) : (
              <td>{formatPhoneNumber(record.phone_number)}</td>
            )}
          </span>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="edit-area">
          <span>
            <Link to={`claim_details/${record.id}`}>
              <img src="assets/images/blue_eye.svg" alt="view-icon" />
            </Link>
          </span>
        </div>
      ),
    },
  ];

  const handleDeleteModal = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  // Dlete Insurance Agency
  const deleteInsurance = async (id) => {
    setLoader(true);
    //  console.log("sandeep",id);
    const requestKey = {
      id: id,
    };
    const requestOption = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
      //   body: JSON.stringify(requestKey),
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/claim-delete/${id}`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      toast.success(data?.message, { autoClose: 2000 });
      setLoader(false);
      setIsModalVisible(false);
      getData();
    }
    if (res.status === 401) {
      //   navigate("/");
      alert("done");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setLoader(false);
    }
  };
  // Pagination API section start
  const getData = useCallback(async (page, pages, search) => {
    console.log(page, "sss");
    setLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/get-claim?limit=${
        page > 25 ? page : 25
      }&page=${pages ? pages : 1}&search=${search === undefined ? '' : search}`,
      requestOption
    );

    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setPageResponse({
        ...pageResponse,
        total: data?.claimList?.total,
        pageCount: data?.claimList?.last_page,
      });
      setData(data?.claimList?.data);
      console.log(data?.claimList?.data, 'data?.claimList?.data');

      // setPageSize(data?.claimList?.data?.length);
      setAllData(data?.claimList);
      setLoader(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: 200,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    }
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }

    if (res.status >= 400 && res.status <= 500) {
      let error = await res.json();
      toast.error(error?.message);
      setLoader(false);
    }
    // eslint-disable-next-line
  }, []);

  // Number split formating "+1 (234) 567-8900" section start
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(2|)?(\d{3})(\d{3})(\d{4})(\d{0,5})$/);
    // console.log('match',match);
    if (match) {
      // var intlCode = (match[1] ? '+1 ' : '');
      // return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
      return [
        "(",
        match[1],
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
        match[5] ? "-" + match[5] : "",
      ].join("");
    }
    return null;
  }

  // eslint-disable-next-line
  const searchWithDebounce = useCallback(debounce(getData, 300), []);

  const searchHandler = (e) => {
    setSearchData(e.target.value);
    setCurrentPage();
    searchWithDebounce("", 1, e.target.value);
  };

  useEffect(() => {
    getData("", pages, currentPage);
  }, [currentPage, getData, pages]);

  // useEffect(()=>{
  //     window.history.pushState(null, null, window.location.href);
  //         window.onpopstate = function () {
  //             window.history.go(1);
  //         };
  //         // eslint-disable-next-line
  // },[window]);

  const sorting = (col) => {
    console.log(col, "col");
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
      // return console.log(sorted, 'sort');
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  const _pageSize = (pages) => {
    setPageSize(pages);
    getData(pages,1,'');
    //   setAllData(allData?.data?.length)
    // setPage(pages)
  };
  console.log(data, "dataasda");

  return (
    <>
      <Sidebar />
      <div className="content">
        <div className=" header">
          <div className="main-container">
            <div className="top-bar">
              <div className="inner-container">
                <div className="top-search-area common">
                  <h1>Claims</h1>
                </div>
                <Logout />
              </div>
            </div>
          </div>
        </div>

        <div className="container-box padd_hTop">
          <div className="boxed-area">
            <div className="boxed-top-bar exPad">
              <div className="tabbed-links">
                <span>Claims List</span>
              </div>

              {/* Search section start */}
              <div className="search-area">
                <div className="search-box">
                  <input
                    type="search"
                    placeholder="Search.."
                    value={searchData}
                    onChange={searchHandler}
                  />
                  <span onClick={() => getData(1, searchData)}>
                    <img
                      src="assets/images/search_icon.png"
                      alt="search-icon"
                    />
                  </span>
                </div>
              </div>
              {/* Search section end */}
            </div>
            <div className="table-responsive rig-table">
              {/* <Table
                columns={columns}
                //   rowKey={(record) => record.login.uuid}
                dataSource={data}
                pagination={false} 
                //   loading={loading}
                // onChange={handleTableChange}
              /> */}
              <table className="table">
                <thead>
                  <tr>
                    <th className="serialTh">S.No.</th>
                    <th className="logoTh">
                      Date
                      <img
                        src="assets/images/sorting.png"
                        onClick={() => sorting("created_at")}
                        className="sorting_img"
                        alt="sorting"
                      />
                    </th>
                    <th className="agencyTh">
                      Insurance Agency
                      <img
                        src="assets/images/sorting.png"
                        onClick={() => sorting("address_2")}
                        className="sorting_img"
                        alt="sorting"
                      />
                    </th>
                    <th className="websiteTh">Full Name</th>
                    <th className="phoneTh">Phone No.</th>
                    <th className="emailTh">
                      Email
                    </th>
                    <th className="actionTh">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allData?.data?.length > 0 ? (
                    data?.map((claimData, index) => {
                      const {
                        id,
                        updated_at,
                        name,
                        email,
                        insurance_agency_id,
                        phone_number,
                        address_2,
                      } = claimData;
                      return (
                        <tr key={index}>
                          <td>{allData?.from + index}</td>
                          <td>{moment(updated_at).format("MMM DD, YYYY")}</td>
                          {insurance_agency_id?.map((tit, id) => {
                            return <td key={id}>{tit?.title}</td>;
                          })}
                          <td>{name}</td>
                          {phone_number?.toString()?.length < 10 ? (
                            <td>Invalid Number</td>
                          ) : (
                            <td>{formatPhoneNumber(phone_number)}</td>
                          )}
                          <td>{email}</td>

                          <td>
                            <div className="edit-area">
                              <span>
                                <Link to={`claim_details/${id}`}>
                                  <img
                                    src="assets/images/blue_eye.svg"
                                    alt="view-icon"
                                  />
                                </Link>
                              </span>
                              <span className="trash_pointer">
                                <img
                                  src="assets/images/trash.svg"
                                  alt="trash"
                                  onClick={() => handleDeleteModal(id)}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : !loader ? (
                    <tr>
                      <td colSpan="10" align="center" className="mt-5">
                        <h1>Data not found</h1>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>

            {/* <!-- Table Responsive Ends here --> */}

            <div className="pagination-footer row m-0">
              {/* Pagination numbering section start */}
              <div className="pagination-area col-sm-6">
                <div className="page-counter">
                  <span className="first">
                    {loader ? (
                      <div className="loader"></div>
                    ) : !allData?.from ? (
                      1
                    ) : (
                      allData?.from
                    )}
                  </span>
                  -
                  <span className="second">
                    {loader ? (
                      <div className="loader"></div>
                    ) : !allData?.to ? (
                      0
                    ) : (
                      allData?.to
                    )}
                  </span>{" "}
                  of {loader ? <div className="loader"></div> : allData?.total}{" "}
                </div>
                <div className="pagination"></div>
              </div>
              {/* Pagination numbering section end */}
              <div className="dash_pagination col-sm-6">
                <nav aria-label="Page navigation example">
                  <Pagination
                    // lastPage={allData.last_page}
                    totalPages={allData?.last_page}
                    currentPage={allData?.current_page}
                    page={pages}
                    setPage={(page) => setPage(page)}
                  />
                  <div className="select-box">
                    {" "}
                    <select
                      value={pageSize}
                      onChange={(e) => {
                        _pageSize(Number(e.target.value));
                      }}
                    >
                      {[25, 50, 100, 150, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </nav>
              </div>
            </div>
            {/* <!-- Pagination Ends Here --> */}
          </div>
        </div>
      </div>
      {/* Loading section start */}
      {loader ? (
        <div className="main-loader">
          <Spinner animation="border" className="loader" variant="light" />
        </div>
      ) : (
        ""
      )}

      {/* Delete modal section start */}

      <Modal
        show={isModalVisible}
        centered
        onHide={() => setIsModalVisible(false)}
        className="delete-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Teacher Name List</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="trash-img">
            <img src="assets/images/trash.svg" alt="trash" />
          </div>
          Do you want to delete?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="cust" onClick={() => setIsModalVisible(false)}>
            No
          </Button>
          <Button
            variant="cust"
            onClick={() => {
              deleteInsurance(deleteId);
              setIsModalVisible(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ClaimsList;
