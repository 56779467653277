import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const InsuranceAgenciesForgot = () => {
  const navigate = useNavigate();

  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(false);

  localStorage.setItem("insurance_forget_password_email", emails);

  // Forget API section start

  const handleForgate = async () => {
    setLoading(true);
    const forgetKey = {
      email: emails,
    };

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(forgetKey),
    };

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/agency/forgot-password`,
      requestOption
    );
    if (response.status >= 200 && response.status <= 399) {
      const data = await response.json();
      setLoading(false);
      toast.success(data?.message);
      // setEmails("");
      setTimeout(() => {
        navigate("/insurance_verification");
      }, 2000);
    }
    if (response.status >= 400 && response.status <= 500) {
      const error = await response.json();
      toast.error(error?.message);
      setLoading(false);
    }
  };
  // Forget API section start

  const forgetPassword = (e) => {
    e.preventDefault();
    if (!emails) {
      toast.error("Please enter the registered email");
    } else {
      handleForgate();
    }
  };
  return (
    <>
      <div id="wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-5 col-xl-6 px-0">
              <div className="doc-area">
                <div className="logo">
                  <img
                    src="assets/images/big-rig-logo.svg"
                    alt="big-rig-logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-7 col-xl-6 px-0">
              <div className="login-area">
                <h1>Forgot Password</h1>
                <form
                  className="login-form"
                  onSubmit={(e) => forgetPassword(e)}
                >
                  <div className="form-group form-floating">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email / Username"
                      value={emails}
                      onChange={(e) => setEmails(e.target.value)}
                    />
                    <label htmlFor="email">Email / Username</label>
                  </div>
                  <button type="submit" className="btn theme-btn">
                    Submit
                  </button>
                  <p className="forgot onforgot">
                    <Link to="/insurance_agency_login">Back to Sign In</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" />

      {/* Loading section start */}
      {loading ? (
        <div className="main-loader">
          <Spinner animation="border" className="loader" variant="light" />
        </div>
      ) : (
        ""
      )}
      {/* Loading section end */}
    </>
  );
};

export default InsuranceAgenciesForgot;
