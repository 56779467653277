import React, { useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "@kaydhiman/react-hook-useform";

import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import Carousel from "react-bootstrap/Carousel";

import Auth from "../../auth/Auth";
import Logout from "../onboarding/Logout";
import Sidebar from "../../sidebar/Sidebar";

import "bootstrap/dist/css/bootstrap.css";
import Pagination from "../pagination/Pagination";

const UserDetails = () => {
  const { id } = useParams();

  const { values, setInitialValues } = useForm({
    validations: {},
  });

  const [pageSize, setPageSize] = useState(25);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [data, setData] = useState([]);

  const [userData, setUserData] = useState([]);
  const [isModalOther, setIsModalOther] = useState(false);
  const [isModalVin, setIsModalVin] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [order, setOrder] = useState("ASC");

  const [pages, setPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [allData, setAllData] = useState({});

  const navigate = useNavigate();

  function debounce(func, timeout = 500) {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const handleUserById = async () => {
    setLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Accept: "Application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/user-details/${id}`,
      requestOption
    );
    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      console.log(data?.user_details, "data");
      setInitialValues(data?.user_details);
      setUserData(data?.user_details);

      // setData(data?.user_details);
      // setAllData(data?.user_details);
      setLoader(false);
    }
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      setLoader(false);
      toast.error(error?.message);
    }
  };

  const _userDetailsInfo = useCallback(
    async (page, pages, search, network = 1) => {
      setLoading(true);
      const requestOption = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Auth.token()}`,
        },
      };
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/user-profile/${id}?limit=${
          page > 25 ? page : 25
        }&page=${pages ? pages : 1}&search=${
          search === undefined ? "" : search
        }`,
        requestOption
      );
      if (res.status >= 200 && res.status <= 399) {
        const data = await res.json();
        // const newList = [ ...data?.claimList?.data];
        setData(data?.user_details?.data);
        console.log(data?.user_details?.data, ".sdsa");

        // setPageSize(data?.user_details?.data?.length);
        setAllData(data?.user_details);
        setLoading(false);
      }
      if (res.status === 401) {
        localStorage.removeItem("token");
        navigate("/");
      }
      if (res.status >= 400 && res.status <= 500) {
        const error = await res.json();
        toast.error(error?.message);
        setLoading(false);
      }
      // eslint-disable-next-line
    },
    []
  );

  // eslint-disable-next-line
  const searchWithDebounce = useCallback(debounce(_userDetailsInfo, 300), []);

  const searchHandler = (e) => {
    setSearchData(e.target.value);
    setCurrentPage();
    searchWithDebounce("", 1, e.target.value);
  };

  useEffect(() => {
    _userDetailsInfo("", pages, currentPage);
  }, [pages, _userDetailsInfo, currentPage]);

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      );
      console.log(sorted, "userData");
      setData(sorted);
      setOrder("ASC");
    }
  };

  const _pageSize = (pages) => {
    setPageSize(pages);
    _userDetailsInfo(pages, 1, "");
    //   setAllData(allData?.data?.length)
    // setPage(pages)
  };

  useEffect(() => {
    if (id) {
      handleUserById();
    }
    // eslint-disable-next-line
  }, []);

  //   Handle modal Image show index
  const handleImage = (id) => {
    setImageIndex(id);
  };

  console.log(data, ">>>>");

  return (
    <>
      <Sidebar />
      <div className="content">
        <div className=" header">
          <div className="main-container">
            <div className="top-bar">
              <div className="inner-container">
                <div className="top-search-area common">
                  <h1>Users</h1>
                </div>
                <Logout />
              </div>
            </div>
          </div>
        </div>
        <div className="container-box main-container padd_hTop mb-5">
          <div className="boxed-area limit-boxed-area mb-5">
            <div className="boxed-top-bar exPad">
              <div className="tabbed-links">
                <span className="back-icon">
                  <Link to="/users">
                    <img
                      src="assets/images/arrow_header.svg"
                      alt="arrow-header"
                    />
                  </Link>
                </span>
                <h2>User Detail</h2>
              </div>
            </div>
            <div className="details-body row">
              <div className="col-lg-8 row">
                <div className="col-md-6  ">
                  <div className="detail-cards">
                    <p className="sub-heading">Full Name</p>
                    <p className="sub-text">
                      {loader ? (
                        <div className="loader"></div>
                      ) : (
                        userData?.map((data, index) => data.name)
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="detail-cards">
                    <p className="sub-heading">Email</p>
                    <p className="sub-text">
                      {loader ? (
                        <div className="loader"></div>
                      ) : (
                        userData?.map((data, index) => data.email)
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="boxed-area mb-5">
            <div className="boxed-top-bar exPad">
              <div className="tabbed-links">
                <span>Insurance Providers and Policy Numbers</span>
              </div>

              <div className="search-area">
                {/* Search field section start */}
                <div className="search-box">
                  <input
                    type="search"
                    placeholder="Search.."
                    value={searchData}
                    onChange={searchHandler}
                  />
                  <span>
                    <img
                      src="assets/images/search_icon.png"
                      alt="search-icon"
                    />
                  </span>
                </div>
                {/* Search field section end */}
              </div>
            </div>

            <div className="table-responsive rig-table">
              <table className="table">
                <thead>
                  <tr>
                    <th className="logoTh">S.No.</th>
                    <th className="peopleTh">
                      Date
                      <img
                        src="assets/images/sorting.png"
                        onClick={() => sorting("created_at")}
                        className="sorting_img"
                        alt="sorting"
                      />
                    </th>
                    <th className="peopleTh">Insurance Provider</th>
                    <th className="peopleTh">Policy Number</th>
                  </tr>
                </thead>
                <tbody>
                  {allData?.data?.length > 0 ? (
                    data?.map((userDetail, index) => {
                      return (
                        <tr key={index}>
                          <td>{allData?.from + index}</td>
                          <td>
                            <div>
                              {moment(userDetail.created_at).format(
                                "MMM DD, YYYY"
                              )}
                            </div>
                          </td>
                          <td>
                            {!userDetail?.insurance_agency_id?.title
                              ? "N/A"
                              : userDetail?.insurance_agency_id?.title}
                          </td>
                          <td>{userDetail.policy_number}</td>
                        </tr>
                      );
                    })
                  ) : !loading ? (
                    <tr>
                      <td colSpan="10" align="center" className="mt-5">
                        <h1>Data not found</h1>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {/* {allData?.data?.length > 0 ? (
                    data?.map((userDatas, index) => {
                      const {
                        created_at,
                        user_profile,
                      } = userDatas;
                      return (
                        <tr key={index}>
                          {console.log(user_profile, "userData")}
                          <td>{allData?.from + index}</td>
                          <td>{moment(created_at).format("MMM DD, YYYY")}</td>
                          <td>
                            {user_profile?.map((data, index) => (
                              <div>
                                
                                {(index ? "" : "") +
                                  data?.insurance_agency_id?.title}
                              </div>
                            ))}
                          </td>
                          <td>
                            {" "}
                            {user_profile?.map((data, index) => (
                              <div>
                                {(index ? "" : "") + data?.policy_number}
                              </div>
                            ))}
                          </td>
                         
                        </tr>
                      );
                    })
                  ) : 
                  
                  !loading ? (
                    <tr>
                      <td colSpan="10" align="center" className="mt-5">
                        <h1>Data not found</h1>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )} */}
                </tbody>
              </table>

              <div className="pagination-footer row m-0">
                <div className="pagination-area col-sm-6">
                  <div className="page-counter">
                    <span className="first">
                      {loader ? (
                        <div className="loader"></div>
                      ) : !allData?.from ? (
                        1
                      ) : (
                        allData?.from
                      )}
                    </span>
                    -
                    <span className="second">
                      {loader ? (
                        <div className="loader"></div>
                      ) : !allData?.to ? (
                        0
                      ) : (
                        allData?.to
                      )}
                    </span>{" "}
                    of{" "}
                    {loader ? <div className="loader"></div> : allData?.total}{" "}
                  </div>
                  <div className="pagination"></div>
                </div>
                <div className="dash_pagination col-sm-6">
                  <nav aria-label="Page navigation example">
                    <Pagination
                      // lastPage={allData.last_page}
                      totalPages={allData?.last_page}
                      currentPage={allData?.current_page}
                      page={pages}
                      setPage={(page) => setPage(page)}
                    />
                    <div className="select-box">
                      {" "}
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          _pageSize(Number(e.target.value));
                        }}
                      >
                        {[25, 50, 100, 150, 200].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetails;
