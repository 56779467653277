import React, { useState,useCallback,useEffect } from "react";
import UserInfo from "./UserInfo";
import Logout from "../onboarding/Logout";
import Sidebar from "../../sidebar/Sidebar";
import Auth from "../../auth/Auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const limit = 25;

function debounce(func, timeout = 500) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

const Users = () => {

    const [network, setNetwork] = useState(1);
    const [loading, setLoading] = useState(false);

    const [networkResponse, setNetworkResponse] = useState([]);
    const [page, setPage] = useState(1);
    const [searchData, setSearchData] = useState("");
    const [currentPage, setCurrentPage] = useState();

    const navigate=useNavigate();

    const inNetwork = useCallback(async (page, search, network = 1) => {
        setLoading(true);
        const requestOption = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Auth.token()}`,
            },
        };
        const res = await fetch(
            `${process.env.REACT_APP_BASE_URL
            }/api/user-list?limit=${limit}&page=${page}&search=${search === undefined ? '' : search
            }&userInfo=${network}`,
            requestOption
        );
        if (res.status >= 200 && res.status <= 399) {
            const data = await res.json();
            setNetworkResponse(data?.claimList);
            setLoading(false);
        };
        if(res.status===401){
            localStorage.removeItem("token");
            navigate("/");
        };
        if (res.status >= 400 && res.status <= 500) {
            const error = await res.json();
            toast.error(error?.message);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, []);

        // eslint-disable-next-line
        const searchWithDebounce = useCallback(debounce(inNetwork, 300), []);

        const searchHandler = (e) => {
            setSearchData(e.target.value);
            setCurrentPage();
            searchWithDebounce(1, e.target.value, network);
        };
    
        useEffect(() => {
            inNetwork(page, currentPage, network);
        }, [page, inNetwork, network, currentPage]);

    return (
        <>
            <Sidebar />
            <div className="content">
                <div className=" header">
                    <div className="main-container ">
                        <div className="top-bar">
                            <div className="inner-container">
                                <div className="top-search-area common">
                                    <h1>Users</h1>
                                </div>
                                <Logout />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-box padd_hTop">
                    <div className="boxed-area">
                        <div className="boxed-top-bar exPad">
                            <div className="tabbed-links">
                            <span>Users List</span>
                            {/* <ul>
                                        <li>
                                            <input
                                                name="network"
                                                type="radio"
                                                id="innetwork"
                                                onChange={() => {setNetwork(0);setPage(1)}}
                                                checked={network === 0}
                                            />
                                            <label htmlFor="innetwork">User Info</label>
                                        </li>

                                        <li>
                                            <input
                                                name="network"
                                                type="radio"
                                                id="outofnetwork"
                                                onChange={() => {setNetwork(1);setPage(1)}}
                                                checked={network === 1}
                                            />
                                            <label htmlFor="outofnetwork">Saved Profile</label>
                                        </li>
                            </ul> */}

                            </div>
                            {/*  Search section start*/}
                            <div className="search-area">
                                    {/* Search field section start */}
                                    <div className="search-box">
                                        <input
                                            type="search"
                                            placeholder="Search.."
                                            value={searchData}
                                            onChange={searchHandler}
                                        />
                                        <span>
                                            <img
                                                src="assets/images/search_icon.png"
                                                alt="search-icon"
                                            />
                                        </span>
                                    </div>
                                    {/* Search field section end */}

                                </div>
                            {/*  Search section start*/}
                        </div>
                        <UserInfo 
                        networkResponse={networkResponse}
                        setNetworkResponse={setNetworkResponse}
                        loading={loading}
                        page={page}
                        setPage={setPage}
                        network={network}
                        />
                    </div>
                </div>
            </div>
        </>
        // <>
        //     <Sidebar />
        //     <div className="content">
        //         <div className=" header">
        //             <div className="main-container ">
        //                 <div className="top-bar">
        //                     <div className="inner-container">
        //                         <div className="top-search-area common">
        //                             <h1>Users</h1>
        //                         </div>
        //                         <Logout />
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="container-box padd_hTop">
        //             <div className="boxed-area">
        //                 <div className="boxed-top-bar">
        //                     <div className="tabbed-links">

        //                     <ul>
        //                                 <li>
        //                                     <input
        //                                         name="network"
        //                                         type="radio"
        //                                         id="innetwork"
        //                                         onChange={() => {setNetwork(0);setPage(1)}}
        //                                         checked={network === 0}
        //                                     />
        //                                     <label htmlFor="innetwork">User Info</label>
        //                                 </li>

        //                                 <li>
        //                                     <input
        //                                         name="network"
        //                                         type="radio"
        //                                         id="outofnetwork"
        //                                         onChange={() => {setNetwork(1);setPage(1)}}
        //                                         checked={network === 1}
        //                                     />
        //                                     <label htmlFor="outofnetwork">Saved Profile</label>
        //                                 </li>
        //                             </ul>

        //                     </div>
        //                     {/*  Search section start*/}
        //                     <div className="search-area">
        //                             {/* Search field section start */}
        //                             <div className="search-box">
        //                                 <input
        //                                     type="search"
        //                                     placeholder="Search.."
        //                                     value={searchData}
        //                                     onChange={searchHandler}
        //                                 />
        //                                 <span>
        //                                     <img
        //                                         src="assets/images/search_icon.png"
        //                                         alt="search-icon"
        //                                     />
        //                                 </span>
        //                             </div>
        //                             {/* Search field section end */}

        //                         </div>
        //                     {/*  Search section start*/}
        //                 </div>
        //                 <UserInfo 
        //                 networkResponse={networkResponse}
        //                 loading={loading}
        //                 page={page}
        //                 setPage={setPage}
        //                 network={network}
        //                 />
        //             </div>
        //         </div>
        //     </div>
        // </>
    )
};
export default Users;