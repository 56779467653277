import React, { useState } from "react";
// import leftArrow from "../../assets/images/leftArrow.png";
// import rightArrow from "../../assets/images/rightArrow.png";

const Pagination = ({ totalPages, currentPage, page, setPage, lastPage }) => {

    localStorage.setItem("storepage",page);
    const getPage = localStorage.getItem("storepage");
    

    const [counter, setCounter] = useState(1);
    //PAGINATION FUNCTION
    function Pagination() {
        let links = [],
            link;

        for (let i = counter; i <= totalPages; i++) {
            link =
                i <= counter + 4 ? (
                    <li
                        className={`page-item ${i === currentPage ? "active" : ""}`}
                        key={i}
                    >
                        <button className="page-link" onClick={() => setPage(i)}>
                            {i}
                        </button>
                    </li>
                ) : i === counter + 5 ? (
                    <button
                        key={i}
                        className="page-link"
                        onClick={() => {
                            setCounter((prevCount) => prevCount + 5);
                            setPage((prevPage) => prevPage + 5);
                        }}
                    >
                        ...
                    </button>
                ) : null;

            links.push(link);
            continue;
        }
        return links;
    }

    return (
        <nav className="">
            <ul className="pagination">
                <li className="page-item">
                    <button
                        className="page-link"
                        aria-label="Previous"
                        onClick={() => {
                            setPage(page - 1);

                            if (currentPage === counter && currentPage > 5) {
                                setCounter(currentPage - 5);
                            }
                        }}
                        disabled={page === 1}
                    >
                        <span aria-hidden="true">
                            {/* <img src="assets/images/previous_icon.svg" alt="previous" /> */}
                            <svg width="14" height="14" viewBox="0 0 14 14">
                                <g
                                    transform="translate(14 0) rotate(90)"
                                    opacity="1"
                                >
                                    <g id="arrow-down">
                                        <path
                                            className="pagination-round"
                                            d="M0,0H14V14H0Z"
                                            transform="translate(14 14) rotate(180)"
                                            fill="none"
                                            opacity="0"
                                        />
                                        <path
                                            className="pagination-arrow"
                                            d="M10.555,0,6.21,4.351a1.322,1.322,0,0,1-1.866,0L0,0"
                                            transform="translate(1.723 4.964)"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </button>
                </li>

                {Pagination()}
                {totalPages < 5 ? "" : <button className="page-item" onClick={() => setPage(lastPage)}>{lastPage}</button>}

                <li className="page-item">
                    <button
                        className="page-link"
                        aria-label="Next"
                        onClick={() => {
                            setPage(page + 1);

                            if (currentPage === counter + 4) {
                                setCounter(currentPage + 1);
                            }
                        }}
                        disabled={+page >= +totalPages}
                    >
                        <span aria-hidden="true">
                            {/* <img src="assets/images/next_icon.svg" alt="next" /> */}
                            <svg width="14" height="14" viewBox="0 0 14 14">
                                <g
                                    id="vuesax_linear_arrow-down"
                                    data-name="vuesax/linear/arrow-down"
                                    transform="translate(0 14) rotate(-90)"
                                >
                                    <g id="arrow-down">
                                        <path
                                            className="pagination-round"
                                            d="M0,0H14V14H0Z"
                                            transform="translate(14 14) rotate(180)"
                                            fill="none"
                                            opacity="0"
                                        />

                                        <path
                                            className="pagination-arrow"
                                            d="M10.555,0,6.21,4.351a1.322,1.322,0,0,1-1.866,0L0,0"
                                            transform="translate(1.723 4.964)"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                        />
                                    </g>
                                </g>
                            </svg>

                        </span>

                    </button>

                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
