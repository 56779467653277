import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useForm, patterns } from "@kaydhiman/react-hook-useform";

import Auth from "../../auth/Auth";
import Logout from "../onboarding/Logout";
import Sidebar from "../../sidebar/Sidebar";

const dropAreaImageStyle = {
  width: "100%",
  height: "100%",
  position: "absolute",
};

const AddInsurenceAgency = () => {
  const { id } = useParams();
  const inputRef = useRef(null);
  const { bindField, values, errors, setInitialValues, isValid } = useForm({
    validations: {
      title: {
        required: true,
        message: "Please Enter The Agency Name",
      },
      email: {
        pattern: {
          value: patterns.email,
          message: "Please enter the valid email address.",
        },
        required: true,
      },
      phone_number: {
        required: {},
        noWhiteSpace: {},
        minLength: {
          value: 10 || 15,
          message: "Please enter a valid phone number",
        },
      },
      web_portal: {
        required: true,
        message: "Please Enter The Website URL",
      },
    },
  });

  // eslint-disable-next-line
  // const expression =/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  // eslint-disable-next-line
  const expression =
    /[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

  const checkHttp = expression?.test(values?.web_portal);
  const location = useLocation();
  const navigate = useNavigate();
  const [attachedFilesData, setAttachedFilesData] = useState({});
  const [dataUri, setDataUri] = useState({});
  const [stateData, setStateData] = useState([]);
  const [insuranceIamge, setInsuranceImage] = useState({});
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [netWork, setNetWork] = useState("");
  const [states, setStates] = useState([]);
  const [netWorkError, setnetWorkError] = useState("");
  const [stateError, setStateError] = useState([]);
  const [logoError, setLogoError] = useState("");
  const [urlError, setUrlError] = useState(false);
  // Upload logo state
  const [showFile, setShowFile] = useState("");
  const [sendFile, setSendFile] = useState("");

  // Insurance Agency State
  const insuranceAgencyState = async () => {
    setLoader(true);
    const requestOption = {
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        Accept: "Application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
    };
    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/state`,
      requestOption
    );
    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setStateData(data?.data);
      setLoader(false);
    }
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setLoader(false);
    }
  };

  console.log(stateData, ">>");

  // edit get data
  const userData = () => {
    let downloadUrl = location?.state?.from;
    console.log(downloadUrl, 'downloadUrl');
    if (downloadUrl) {
      setNetWork(downloadUrl?.bigrig_verified);
      setStates(downloadUrl?.state?.map((item) => item?.state_name));
      setInitialValues({
        title: downloadUrl?.title,
        email: downloadUrl?.email,
        phone_number: downloadUrl?.phone_number,
        web_portal: downloadUrl?.web_portal,
      });
      setInsuranceImage({
        img: downloadUrl?.logo,
        url: `${process.env.REACT_APP_BASE_URL}/images/${downloadUrl?.logo}`,
      });
      setAttachedFilesData({
        img: downloadUrl?.logo,
        url: `${process.env.REACT_APP_BASE_URL}/images/${downloadUrl?.logo}`,
      });
      setDataUri({
        img: downloadUrl?.logo,
        url: `${process.env.REACT_APP_BASE_URL}/images/${downloadUrl?.logo}`,
      });
    }
  };

  // Upload logo API
  const uploadLogo = async () => {
    setLoader(true);

    if (!((dataUri?.img || sendFile) instanceof File)) {
      createInsurance(dataUri?.img || sendFile);
      return;
    }

    const formData = new FormData();
    formData.append("image", dataUri?.img || sendFile);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/upload-image`,
      requestOptions
    );
    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      setLoader(false);
      createInsurance(data?.image);
    }
    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }
    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setLoader(false);
    }
  };

  console.log(dataUri, attachedFilesData, "dataUri");

  // Add Insurance Agency
  const createInsurance = async (logo) => {
    setLoader(true);
    const bodyData = {
      ...values,
      ...(id ? { id: id } : {}),
      bigrig_verified: netWork,
      logo,
      states: states?.map((item) => item?.id),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.token()}`,
      },
      body: JSON.stringify(bodyData),
    };

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/save-insurance-agency`,
      requestOptions
    );
    if (res.status >= 200 && res.status <= 399) {
      const data = await res.json();
      toast.success(data.message);
      setTimeout(() => {
        navigate("/insurance");
      }, 1500);
      setLoader(false);
    }

    if (res.status === 401) {
      localStorage.removeItem("token");
      navigate("/");
    }

    if (res.status >= 400 && res.status <= 500) {
      const error = await res.json();
      toast.error(error?.message);
      setLoader(false);
    }
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      // reader.onload = (event) => {
      //   resolve(event.target.result)
      // };
      reader.onload = (loadEvt) => {
        setInsuranceImage({ img: file[0], url: loadEvt.target.result });
      };
      reader.onload = (loadEvt) => {
        resolve(loadEvt.target.result);
      };
      reader.readAsDataURL(file[0]);
    });

  //-------ATTACHED FILES DROPDOWN FUNCTIONALITY -------//
  const onChangeAttachedFilesData = (e) => {
    const data = e.target.files;

    const reader = new FileReader();

    reader.onload = (loadEvt) => {
      setDataUri({ img: data[0], url: loadEvt.target.result });
    };

    reader.readAsDataURL(data[0]);

    for (let elem of data) {
      if (elem.size >= 2e6) {
        setErr("File size exceeded the limit of 2MB");
        return;
      } else {
        setAttachedFilesData({
          name: elem.name,
          file: elem,
          url: `${process.env.REACT_APP_BASE_URL}/images/${elem?.name}`,
        });
        // setDataUri({
        //   name: elem.name,
        //   file: elem,
        //   url: `${process.env.REACT_APP_BASE_URL}/images/${elem?.name}`,
        // });
      }
    }
  };

  // const onChangeAttachedFilesData = (e) => {
  //   const data = e.target.files;
  //   let totalPhotosLength = data.length + attachedFilesData.length;

  //   if (totalPhotosLength > 10) {
  //     alert("only ten files you can added");
  //     return;
  //   }

  //   for (let elem of data) {
  //     if (elem.size >= 2500000) {
  //       toast.error("File size too large,upload upto 2.5mb.", {
  //         autoClose: 3000,
  //       });
  //       return;
  //     } else {
  //       setAttachedFilesData((prevState) => {
  //         return [...prevState, { name: elem.name, file: elem }];
  //       });
  //     }
  //   }
  // };

  // Image uploader handlechange  function start
  const onDropHandler = (e) => {
    e.preventDefault();

    const files = e.dataTransfer.files;

    const reader = new FileReader();
    if (files.length === 0) {
      return false;
    }
    const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
    const { size, type } = files[0];

    if (!fileTypes.includes(type)) {
      setErr("File format must be either png or jpg");
      return false;
    }
    if (size / 1024 / 1024 > 2) {
      setErr("File size exceeded the limit of 2MB");
      return false;
    }
    setErr(false);

    reader.readAsDataURL(files[0]);

    reader.onload = (loadEvt) => {
      setInsuranceImage({ img: files[0], url: loadEvt.target.result });
    };
    reader.onload = (loadEvt) => {
      setDataUri({ img: files[0], url: loadEvt.target.result });
    };
    // setAttachedFilesData({
    //   name: elem.name,
    //   file: elem,
    //   url: `${process.env.REACT_APP_BASE_URL}/images/${elem?.name}`,
    // });
  };

  const onDragOverHandler = (e) => {
    e.preventDefault();
  };

  // Image uploader handlechange  function end
  const handleAddNewInsuranceAgency = (e) => {
    e.preventDefault();

    if (!(netWork === 0 || netWork === 1)) {
      setnetWorkError("Please select the network");
      return;
    } else if (netWork === 0 || netWork === 1) {
      setnetWorkError("");
    }
    if (states.length <= 0) {
      setStateError("Please select the state");
      return;
    } else {
      setStateError("");
    }
    if (!dataUri.img && !showFile) {
      setLogoError("Please upload the logo");
      return;
    } else {
      setLogoError("");
    }
    if (checkHttp === false) {
      setUrlError(true);
      return;
    } else if (checkHttp === true) {
      setUrlError("");
    }

    uploadLogo();
  };

  //  handle click upload logo
  const handleFileImage = (e) => {
    const fileImage = URL.createObjectURL(e.target.files[0]);
    setShowFile(fileImage);
    setSendFile(e.target.files[0]);
  };

  // const handleSameFile=(e)=>{
  //   setSendFile("");
  //   setShowFile("");
  //   setInsuranceImage({})
  //   return e.target.value==null
  // }

  useEffect(() => {
    insuranceAgencyState();
    userData();
    // eslint-disable-next-line
  }, []);

  // Handle cross button
  const handleCross = () => {
    setInsuranceImage({});
    setAttachedFilesData({});
    // setDataUri({img:'', url:''});
    // setDataUri({});
    // ref.current.value = ""
    inputRef.current.value = null;
    setShowFile("");
    setErr(false);
  };
  const resetFileInput = () => {
    // 👇️ reset input value
    setDataUri({});
    inputRef.current.value = "";
  };

  return (
    <>
      <Sidebar />
      <div className="content">
        <div className=" header">
          <div className="main-container">
            <div className="top-bar">
              <div className="inner-container">
                <div className="top-search-area common">
                  <h1>Insurance Agencies</h1>
                </div>
                <Logout />
              </div>
            </div>
          </div>
        </div>
        <div className="container-box">
          <div className="boxed-area">
            <div className="boxed-top-bar exPad">
              <div className="tabbed-links">
                <span className="back-icon">
                  <Link to="/insurance">
                    <img
                      src="assets/images/arrow_header.svg"
                      alt="arrow-header"
                    />
                  </Link>
                </span>
                {id ? (
                  <h2>Edit New Insurance Agency</h2>
                ) : (
                  <h2>Add New Insurance Agency</h2>
                )}
              </div>
            </div>
            {/* <!-- Boxed Top Bar Ends Here --> */}
            <div className="boxed-main-container">
              <form className="rig-form row">
                <div className="col-md-12 d-flex flex-column mx-auto position-relative">
                  <label className="user-pic-label" htmlFor="user-pic">
                    Upload Logo
                  </label>

                  <div className="image_field">
                    {" "}
                    <div className="user-pic" id="user-pic">
                      {/* {demo&&<img src={demo} alt="demo"/>} */}
                      <label htmlFor="uploadbtn">
                        CLICK and UPLOAD
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          ref={inputRef}
                          id="myFile"
                          onChange={(e) => {
                            onChangeAttachedFilesData(e);
                          }}
                        />
                      </label>
                      <span className="image_size">
                        {!(dataUri?.img || showFile) ? (
                          <>
                            <img
                              src="assets/images/uploadImg.png"
                              alt="gallery-icon"
                            />
                          </>
                        ) : (
                          <>
                            {(dataUri?.img || showFile) && (
                              <img
                                src={dataUri?.url || showFile}
                                alt="insuranceIamge"
                              />
                            )}
                            {!(dataUri?.img || showFile) && (
                              <img
                                src="assets/images/uploadImg.png"
                                alt="insuranceIamge"
                              />
                            )}
                          </>
                        )}
                      </span>
                    </div>
                  </div>

                  {err && !showFile ? (
                    <p className="error_hendling">{err}</p>
                  ) : showFile ? (
                    ""
                  ) : (
                    ""
                  )}
                  <span className="error">
                    {!(attachedFilesData?.file || showFile) ? logoError : ""}
                  </span>
                  {/* Image error message end */}
                </div>
                <div className="input-area row">
                  <div className="col-md-6 col-xl-4">
                    <div className="input-comp mt-3">
                      <label htmlFor="agency-name">Agency Name</label>
                      <div className="agency-name">
                        <input
                          type="text"
                          className={
                            values.title?.length > 0 ? "text_field" : ""
                          }
                          autoComplete="off"
                          placeholder="Enter name"
                          value={values.title}
                          {...bindField("title")}
                        />
                        <span className="error">{errors?.title}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="input-comp">
                      <label htmlFor="agency-name">Email</label>
                      <div className="agency-email">
                        <input
                          type="email"
                          className={
                            values.email?.length > 0 ? "text_field" : ""
                          }
                          placeholder="Enter email"
                          autoComplete="off"
                          value={values?.email}
                          {...bindField("email")}
                        />
                        <span className="error">{errors?.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="input-comp">
                      <label htmlFor="phone-num">Phone No.</label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className={
                          values.phone_number?.length > 0 ? "text_field" : ""
                        }
                        name="phone-num"
                        placeholder="Enter phone"
                        autoComplete="off"
                        maxLength={15}
                        value={values?.phone_number}
                        {...bindField("phone_number")}
                      />
                      <span className="error">{errors.phone_number}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="input-comp">
                      <label htmlFor="website-url">Website URL</label>
                      <input
                        type="text"
                        className={
                          values.web_portal?.length ? "text_field" : ""
                        }
                        name="website-url"
                        placeholder="Enter url"
                        value={values?.web_portal}
                        autoComplete="off"
                        {...bindField("web_portal")}
                      />
                      {/* <span className="error">{errors.web_portal}</span> */}
                      <div>
                        {!checkHttp && values?.web_portal ? (
                          <span className="error">Invalid URL</span>
                        ) : urlError ? (
                          <span></span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="input-comp">
                      <label htmlFor="select-network">Select Network</label>
                      <div className="form-radio">
                        <label>
                          <input
                            type="radio"
                            value={"1" || ""}
                            onChange={(e) => setNetWork(+e.target.value)}
                            name="network"
                            // {...bindField("network")}
                            checked={netWork === 1}

                            // checked={(values?.netWork === '1' && values?.netWork !== undefined) ? true : false}
                          />
                          <span className="radio-label">Network</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            value={"0" || ""}
                            onChange={(e) => setNetWork(+e.target.value)}
                            name="network"
                            // {...bindField("network")}
                            checked={netWork === 0}
                            // checked={(values?.netWork === '1' && values?.netWork !== undefined) ? false : true}
                          />

                          <span className="radio-label">Out of Network</span>
                        </label>
                      </div>
                      <span className="error">
                        {netWork === 0 ? "" : netWork === 1 ? "" : netWorkError}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="input-comp multi_selected">
                      <label htmlFor="subjects">State</label>
                      <div className="dropdown-box">
                        <Typeahead
                          className="arrow"
                          id="public-methods-example"
                          labelKey={(item) => item?.name}
                          multiple
                          options={stateData?.map((item) => ({
                            name: item?.name,
                            id: item?.id,
                          }))}
                          placeholder="Select"
                          selected={states}
                          onChange={(e) => setStates(e)}
                        />
                        <span className="error">
                          {states?.length > 0 ? "" : stateError}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 btn-area">
                    <Link to="/insurance">
                      <button type="button" className="btn cancel">
                        Cancel
                      </button>
                    </Link>

                    <button
                      type="button"
                      onClick={handleAddNewInsuranceAgency}
                      className="btn save theme-btn"
                      disabled={!isValid()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
              <div className="reameBtn">
                {(dataUri?.img || showFile) && (
                  <input
                    type="image"
                    onClick={resetFileInput}
                    src="assets/images/cross.svg"
                    alt="dlt_img"
                    name="saveForm"
                    className="btTxt submit"
                    id="saveForm"
                  />
                )}
              </div>
            </div>
          </div>
          {/* <!-- Boxed Area ends here --> */}
        </div>
      </div>
      <ToastContainer theme="dark" />
      {/* Loading section start */}
      {loader && (
        <div className="main-loader">
          <Spinner animation="border" className="loader" variant="light" />
        </div>
      )}
    </>
  );
};
export default AddInsurenceAgency;
