import React, { useCallback, useEffect, useState } from "react";

import Auth from "../../auth/Auth";
import Pagination from "../pagination/Pagination";

import Spinner from "react-bootstrap/Spinner";

import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../sidebar/Sidebar";
import Logout from "../onboarding/Logout";

const getNetwork = localStorage.getItem("network");
const storepage = localStorage.getItem("storepage");

const convertNumber = Number(getNetwork);
const convertNumberPage = Number(storepage);

const InNetwork = React.memo(
  (
    {
      // networkResponse,
      // setNetworkResponse,
      // loading,
      // setLoading,
      // formatPhoneNumber,
      // page,
      // setPage,
      // network,
    }
  ) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [notDelete, setNotDelete] = useState(false);
    const [order, setOrder] = useState("ASC");
    const [userData, setUserData] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [network, setNetwork] = useState(Number(localStorage.getItem("network")));
    const [networkResponse, setNetworkResponse] = useState({});
    const [pages, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    console.log(Number(localStorage.getItem("network")), '>>>>>>>>>>>>');
    const handleChangeTab = (e) => {
      console.log("00", +e.target.value);
      localStorage.setItem("network", +e.target.value);
      setNetwork(+e.target.value);
      setPage(1);
    };

    console.log(network, '1111');
    function debounce(func, timeout = 500) {
      let timer;

      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }

    const limit = 25;

    const navigate = useNavigate();

    const handleDeleteModal = (data,id, linked, user_count) => {
    //  return console.log(data, user_count,linked, 'user_count');
      setDeleteId(id);
      // if (linked > 0 || user_count > 0) {
      if (linked > 0 ) {

        setNotDelete(true);
      } else {
        setIsModalVisible(true);
      }
    };

    // const inNetwork = useCallback(async (page, network = 0, search) => {
    //   setLoading(true);
    //   const requestOption = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       Authorization: `Bearer ${Auth.token()}`,
    //     },
    //   };
    //   const res = await fetch(
    //     `${
    //       process.env.REACT_APP_BASE_URL
    //     }/api/insurance-angencies-list?limit=${page > 25 ? page : 25}&page=${1}&network=${network}${
    //       search ? "&search=" + search : ""
    //     }`,
    //     requestOption
    //   );

    //   if (res.status >= 200 && res.status <= 399) {
    //     const data = await res.json();
    //     setNetworkResponse(data?.claimList);
    //     setUserData(data?.claimList?.data);
    //     setLoading(false);
    //   }
    //   if (res.status === 401) {
    //     localStorage.removeItem("token");
    //     navigate("/");
    //   }

    //   if (res.status >= 400 && res.status <= 500) {
    //     const error = await res.json();
    //     toast.error(error?.message);
    //     setLoading(false);
    //   }
    //   // eslint-disable-next-line
    // }, []);
    const inNetwork = useCallback(async (page, pages, networks = 1, search) => {
      console.log(network, 'network>>>>>');
      setLoading(true);
      const requestOption = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Auth.token()}`,
        },
      };
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/insurance-angencies-list?limit=${
          page > 25 ? page : 25
        }&page=${pages ? pages : 1}&network=${Number(localStorage.getItem("network")) === 1 ? networks : 0}${
          search ? "&search=" + search : ""
        }`,
        requestOption
      );

      if (res.status >= 200 && res.status <= 399) {
        const data = await res.json();
        setNetworkResponse(data?.claimList);
        setUserData(data?.claimList?.data);
        console.log(data?.claimList?.data, "data?.claimList?.data");
        setLoading(false);
      }
      if (res.status === 401) {
        localStorage.removeItem("token");
        navigate("/");
      }

      if (res.status >= 400 && res.status <= 500) {
        const error = await res.json();
        toast.error(error?.message);
        setLoading(false);
      }
      // eslint-disable-next-line
    }, []);

    // Number split formating "+1 (234) 567-8900" section start
    function formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(2|)?(\d{3})(\d{3})(\d{4})(\d{0,5})$/);
      // console.log('match',match);
      if (match) {
        // var intlCode = (match[1] ? '+1 ' : '');
        // return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
        return [
          "(",
          match[1],
          match[2],
          ") ",
          match[3],
          "-",
          match[4],
          match[5] ? "-" + match[5] : "",
        ].join("");
      }
      return null;
    }

    // eslint-disable-next-line
    const searchWithDebounce = useCallback(debounce(inNetwork, 300), []);

    const searchHandler = (e) => {
      searchWithDebounce("", 1, network, e.target.value);
    };

    // Dlete Insurance Agency
    const deleteInsurance = async (id) => {
      console.log(id, 'iddd');
      setLoading(true);
      //  console.log("sandeep",id);
      const requestKey = {
        id: id,
      };
      const requestOption = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.token()}`,
        },
        body: JSON.stringify(requestKey),
      };

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/delete-insurance-agency`,
        requestOption
      );

      if (res.status >= 200 && res.status <= 399) {
        const data = await res.json();
        toast.success(data?.claimList, { autoClose: 2000 });
        setLoading(false);
        setIsModalVisible(false);
        inNetwork('','',network === 1 ? 1 : 0 )
        setNetworkResponse((prevData) => ({
          ...prevData,
          total: prevData?.total - 1,
          to: prevData?.to - 1,
          data: prevData?.data?.filter((item) => item?.id !== id),
        }));
      }
      if (res.status === 401) {
        localStorage.removeItem("token");
        navigate("/");
      }
      if (res.status >= 400 && res.status <= 500) {
        const error = await res.json();
        toast.error(error?.message);
        setLoading(false);
      }
    };

    const sorting = (col) => {
      console.log(col, "col");
      if (order === "ASC") {
        const sorted = [...userData].sort((a, b) =>
          a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
        );
        setUserData(sorted);
        console.log(sorted, "sorted");
        setOrder("DSC");
      }
      if (order === "DSC") {
        const sorted = [...userData].sort((a, b) =>
          a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
        );
        setUserData(sorted);
        setOrder("ASC");
      }
    };

    const _pageSize = (pages) => {
      setPageSize(pages);
      inNetwork(pages,'',network ===1 ? 1 : 0);
      //   setAllData(allData?.data?.length)
      // setPage(pages)
    };

    // when data all delete in one page then automatically back page
    useEffect(() => {
      if (
        networkResponse?.last_page > 1 &&
        networkResponse?.data?.length === 0
      ) {
        setPage(networkResponse?.last_page - 1);
      }
    }, [networkResponse?.data, networkResponse?.last_page, setPage]);

    useEffect(() => {
      inNetwork("", pages, network);
    }, [pages, network, inNetwork]);

    console.log(userData, "response");
    return (
      <>
        <Sidebar />
        <div className="content">
          <div className=" header">
            <div className="main-container">
              <div className="top-bar">
                <div className="inner-container">
                  <div className="top-search-area common">
                    <h1>Insurance Agencies</h1>
                  </div>
                  <Logout />
                </div>
              </div>
            </div>
          </div>
          {
            console.log(parseInt(localStorage.getItem("network")) , 'Number(localStorage.getItem("network"))')
          }
          <div>
            <div className="container-box padd_hTop">
              <div className="boxed-area">
                <div className="boxed-top-bar">
                  <div className="tabbed-links">
                    <ul>
                      <li>
                        <input
                          name="network"
                          type="radio"
                          id="innetwork"
                          value={1}
                          onChange={handleChangeTab}
                          checked={network === 1}
                        />
                        <label htmlFor="innetwork">In-Network</label>
                      </li>

                      <li>
                        <input
                          name="network"
                          type="radio"
                          id="outofnetwork"
                          value={0}
                          onChange={handleChangeTab}
                          checked={network === 0}
                        />
                        <label htmlFor="outofnetwork">Out-of-Network</label>
                      </li>
                    </ul>
                  </div>
                  <div className="search-area">
                    {/* Search field section start */}
                    <div className="search-box">
                      <input
                        type="search"
                        placeholder="Search.."
                        // value={search}
                        onChange={searchHandler}
                      />
                      <span>
                        <img
                          src="assets/images/search_icon.png"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    {/* Search field section end */}

                    <Link to="add_insurance_agency" className="btn">
                      Add New
                    </Link>
                  </div>
                </div>

                <div className="table-responsive rig-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="serialTh">S.No.</th>
                        <th className="logoTh">Logo</th>
                        <th className="agencyTh">
                          Agency Name
                          <img
                            src="assets/images/sorting.png"
                            onClick={() => sorting("title")}
                            className="sorting_img"
                            alt="sorting"
                          />
                        </th>
                        <th className="emailTh">Email</th>
                        <th className="phoneTh extra_digit">Phone No.</th>
                        <th className="websiteTh">Website</th>
                        {/* {network === 0 && <th className="peopleTh">Linked</th>} */}
                        <th className="actionTh">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {networkResponse?.data?.length > 0 ? (
                        userData?.map((nt, index) => {
                          const {
                            logo,
                            email,
                            phone_number,
                            web_portal,
                            linked,
                            id,
                            title,
                            user_count,
                          } = nt;
                          return (
                            <tr key={index}>
                              <td>
                                {loading ? (
                                  <div className="loader iamge_loader"></div>
                                ) : (
                                  networkResponse?.from + index
                                )}
                              </td>
                              <td>
                                {loading ? (
                                  <div className="loader iamge_loader"></div>
                                ) : (
                                  <div className="radius-def">
                                    <img
                                      src={`${
                                        logo === "" ||
                                        logo === null ||
                                        logo === undefined
                                          ? `assets/images/in_network_logo.svg`
                                          : `${process.env.REACT_APP_BASE_URL}/images/${logo}`
                                      }`}
                                      alt="table-logo"
                                    />
                                  </div>
                                )}
                              </td>
                              <td>{title}</td>
                              <td>{email}</td>
                              <td>{formatPhoneNumber(phone_number)}</td>
                              <td>{web_portal}</td>
                              {/* {network === 0 && <td>{linked}</td>} */}
                              <td>
                                <div className="edit-area">
                                  <span>
                                    <Link
                                      to={`/insurance/add_insurance_agency/${id}`}
                                      state={{ from: nt }}
                                    >
                                      <img
                                        src="assets/images/edit_icon.svg"
                                        alt="edit-icon"
                                      />
                                    </Link>
                                  </span>
                                  <span className="trash_pointer">
                                    <img
                                      src="assets/images/trash.svg"
                                      alt="trash"
                                      onClick={() =>
                                        handleDeleteModal(nt,id, linked, user_count)
                                      }
                                    />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : !loading ? (
                        <tr>
                          <td colSpan="10" align="center" className="mt-5">
                            <h1>Data not found</h1>
                          </td>
                        </tr>
                      ) : (
                        <tr></tr>
                      )}
                    </tbody>
                  </table>
                  {/* {data.map((pd, index) => {
                return (
                    <div key={index}>
                        <p>{pd.title}</p>
                        <img src={pd.thumbnailUrl} alt="thumbnailUrl" />
                    </div>
                )
            })} */}
                </div>
                <div className="pagination-footer row m-0">
                  {/* Pagination numbering section start */}
                  <div className="pagination-area col-sm-6">
                    <div className="page-counter">
                      <span className="first">
                        {loading ? (
                          <div className="loader"></div>
                        ) : !networkResponse?.from ? (
                          1
                        ) : (
                          networkResponse?.from
                        )}
                      </span>
                      -
                      <span className="second">
                        {loading ? (
                          <div className="loader"></div>
                        ) : !networkResponse?.to ? (
                          0
                        ) : (
                          networkResponse?.to
                        )}
                      </span>
                      of{" "}
                      {loading ? (
                        <div className="loader"></div>
                      ) : (
                        networkResponse?.total
                      )}
                    </div>
                    <div className="pagination"></div>
                  </div>
                  {/* Pagination numbering section end */}
                  <div className="dash_pagination col-sm-6">
                    <nav aria-label="Page navigation example">
                      <Pagination
                        // lastPage={networkResponse.last_page}
                        totalPages={networkResponse?.last_page}
                        currentPage={networkResponse?.current_page}
                        page={pages}
                        setPage={(page) => setPage(page)}
                      />
                      <div className="select-box">
                        {" "}
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            _pageSize(Number(e.target.value));
                          }}
                        >
                          {[25, 50, 100, 150, 200].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Pagination Ends Here --> */}

        {/* Delete modal section start */}

        <Modal
          show={isModalVisible}
          centered
          onHide={() => setIsModalVisible(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Teacher Name List</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="trash-img">
              <img src="assets/images/trash.svg" alt="trash" />
            </div>
            Do you want to delete?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="cust" onClick={() => setIsModalVisible(false)}>
              No
            </Button>
            <Button
              variant="cust"
              onClick={() => {
                deleteInsurance(deleteId);
                setIsModalVisible(false);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Not delete data modal */}
        <Modal
          show={notDelete}
          centered
          onHide={() => setNotDelete(false)}
          className="delete-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>There are claims associated with this Agency. It cannot be deleted.</Modal.Body>
        </Modal>

        {/* Loading section start */}
        {loading ? (
          <div className="main-loader">
            <Spinner animation="border" className="loader" variant="light" />
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
);
export default InNetwork;
