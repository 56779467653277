import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import OtpTimer from "otp-timer";

import Spinner from "react-bootstrap/Spinner";

const InsuranceAgenciesVarification = () => {
    // Localstorage get Email section start

    const getEmail = localStorage.getItem("insurance_forget_password_email");

    // Localstorage get Email section start

    const navigate = useNavigate();

    const [otpValue, setOtpValue] = useState("");
    const [loading, setLoading] = useState(false);

    // Verification OTP section start

    const verificationOtp = async () => {
        setLoading(true);
        const keyValue = {
            email: getEmail,
            otp: otpValue
        }
        const requestOption = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(keyValue)
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/agency/verify-otp`, requestOption);
        if (response.status >= 200 && response.status <= 399) {
            const data = await response.json()
            toast.success(data.message);
            setLoading(false);
            setOtpValue("");
            setTimeout(() => {
                navigate("/insurance_reset_password");
            }, 1000)
        }
        if (response.status >= 400 && response.status <= 500) {
            const data = await response.json();
            toast.error(data.message)
            setLoading(false);
            setOtpValue("");
        }

    }
    // Verification OTP section start

    const getOtpHandle = (e) => {
        e.preventDefault();
        if (!otpValue) {
            toast.error("Please enter the verification code")
        } else {
            verificationOtp();
        }
    };

    // Again forget-password Api hit section start
    const submit = async () => {
        setLoading(true);
        const forgetKey = {
            email: getEmail
        }
        const request = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(forgetKey)
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/agency/forgot-password`, request);

        if (response.status >= 200 && response.status <= 399) {
            const data = await response.json();
            setLoading(false);
            toast.success(data.message);
        };
        if (response.status >= 400 && response.status <= 500) {
            const errorData = await response.json();
            console.log("errorData", errorData);
            setLoading(false);
            toast.error("Something went wrong")
        }
    };
    // Again forget-password Api hit section end
  return (
    <>
            <div id="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-lg-5 col-xl-6 px-0">
                            <div className="doc-area">
                                <div className="logo">
                                    <img src="assets/images/big-rig-logo.svg" alt="big-rig-logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-7 col-xl-6 px-0">
                            <div className="login-area">
                                <h1>Verification Code</h1>
                                <form className="login-form" onSubmit={(e) => getOtpHandle(e)}>
                                    <div className="form-group form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Code"
                                            maxLength={6}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            value={otpValue}
                                            onChange={(e) => setOtpValue(e.target.value)}
                                        />
                                        <label for="otpcode">Enter Code</label>
                                    </div>
                                    {/* <Link to="/reset_password"> */}
                                    <button
                                        type="submit"

                                        className="mb-4 forgot-submit theme-btn"
                                    >Verify</button>
                                    {/* </Link> */}
                                    {/* OTP Timer button section start */}
                                    <p className="forgot onforgot">
                                        <OtpTimer
                                            minutes={2}
                                            seconds={0}
                                            text="Time:"
                                            ButtonText="Resend Code"
                                            resend={submit}
                                            textColor={"#000"}
                                            buttonColor={"#2C8FF2"}
                                            background={"transparent"}
                                            className={"hello"}
                                            fontFamily={"OpenSans Semibold"}
                                        />
                                    </p>
                                    {/* OTP Timer button section end */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="dark" />
            {/* Loading section start */}
            {loading ? (
                <div className="main-loader">
                    <Spinner animation="border" className="loader" variant="light" />
                </div>
            ) : (
                ""
            )}
            {/* Loading section end */}
        </>
  );
};

export default InsuranceAgenciesVarification;