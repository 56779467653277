import React, { useState } from "react";

import { HashRouter, Route, Routes } from "react-router-dom";

import Users from "./component/users/Users";
import Login from "./component/onboarding/Login";
import ClaimsList from "./component/claims/ClaimsList";
import Insurence from "./component/insuranceagencies/Insurence";
import AddInsurenceAgency from "./component/insuranceagencies/AddInsurenceAgency";
import Forget from "./component/onboarding/Forget";
import ClaimDetails from "./component/claims/ClaimDetails";
import Verification from "./component/onboarding/Verification";
import ResetPassword from "./component/onboarding/ResetPassword";
import PublicRoute from "./publicprivateroute/PublicRoute";
import PrivateRoute from "./publicprivateroute/PrivateRoute";

import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import UserDetails from "./component/users/UserDetails";
import InNetwork from "./component/insuranceagencies/InNetwork";
import UserInfo from "./component/users/UserInfo";
import InsuranceAgenciesLogin from "./component/insuranceagenciesonboarding/InsuranceAgenciesLogin";
import InsuranceAgenciesForgot from "./component/insuranceagenciesonboarding/InsuranceAgenciesForgot";
import InsuranceAgenciesVarification from "./component/insuranceagenciesonboarding/InsuranceAgenciesVarification";
import InsuranceAgenciesRest from "./component/insuranceagenciesonboarding/InsuranceAgenciesRest";
import InsuranceAgenciesClaimList from "./component/insuranceagenciescomponents/InsuranceAgenciesClaimList";
import InsurancePublicRoute from "./publicprivateroute/InsurancePublicRoute";
import InsurancePrivateRoute from "./publicprivateroute/InsurancePrivateRoute";
import InsuranceAgenciesClaimDetail from "./component/insuranceagenciescomponents/InsuranceAgenciesClaimDetail";
import InsuranceProfile from "./component/insuranceagenciescomponents/insuranceprofile/InsuranceProfile";

const App = () => {
  const [loader, setLoader] = useState(true);
  const [toggleButton, setToggleButton] = useState(false);

  setTimeout(() => {
    setLoader(false);
  }, 2000);

  return (
    <>
      <div className={`toggle_button ${toggleButton ? `toggle_small` : ``}`}>
        <img
          src="assets/images/menu.png"
          alt="menu"
          onClick={() => setToggleButton(!toggleButton)}
        />
      </div>
      {/* Loader section start */}
      {loader ? (
        <div className="spin-wrapper">
          <img
            src="assets/images/big_rig_logo.png"
            alt="logo_loader"
            className="logo_loader"
          />
          <div className="spinner"></div>
        </div>
      ) : (
        ""
      )}
      {/* Loader section end */}

      <div className={`wrapper ${toggleButton ? `toggle_wrapper` : ``}`}>
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />

            {/* insurance Routes */}

            <Route
              path="/insurance_agency_login"
              element={
                <InsurancePublicRoute>
                  <InsuranceAgenciesLogin />
                </InsurancePublicRoute>
              }
            />
            <Route
              path="/insurance_claims_list"
              element={
                <InsurancePrivateRoute>
                  <InsuranceAgenciesClaimList />
                </InsurancePrivateRoute>
              }
            />

            <Route
              path="/insurance_claims_list/insurance_claim_details/:id"
              element={
                <InsurancePrivateRoute>
                  <InsuranceAgenciesClaimDetail />
                </InsurancePrivateRoute>
              }
            />

            <Route
              path="/insurance_profile"
              element={
                <InsurancePrivateRoute>
                  <InsuranceProfile />
                </InsurancePrivateRoute>
              }
            />

            <Route
              path="/forget_password"
              element={
                <PublicRoute>
                  <Forget />
                </PublicRoute>
              }
            />
            <Route
              path="/insurance_forget_password"
              element={
                <PublicRoute>
                  <InsuranceAgenciesForgot />
                </PublicRoute>
              }
            />
            <Route
              path="/reset_password"
              element={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
            <Route
              path="/insurance_reset_password"
              element={
                <PublicRoute>
                  <InsuranceAgenciesRest />
                </PublicRoute>
              }
            />
            <Route
              path="/verification"
              element={
                <PublicRoute>
                  <Verification />
                </PublicRoute>
              }
            />
            <Route
              path="/insurance_verification"
              element={
                <PublicRoute>
                  <InsuranceAgenciesVarification />
                </PublicRoute>
              }
            />

            <Route
              path="/insurance"
              element={
                <PrivateRoute>
                  <InNetwork />
                </PrivateRoute>
              }
            />
            <Route
              path="/claims_list"
              element={
                <PrivateRoute>
                  <ClaimsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/claims_list/claim_details/:id"
              element={
                <PrivateRoute>
                  <ClaimDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <UserInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/users/users_details/:id"
              element={
                <PrivateRoute>
                  <UserDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/insurance/add_insurance_agency/:id"
              element={
                <PrivateRoute>
                  <AddInsurenceAgency />
                </PrivateRoute>
              }
            />
            <Route
              path="/insurance/add_insurance_agency"
              element={
                <PrivateRoute>
                  <AddInsurenceAgency />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Login />} />
          </Routes>
        </HashRouter>
      </div>
    </>
  );
};

export default App;
